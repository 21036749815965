import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query Team($id: ID!) {
    team(id: $id) {
      ...TeamItem
    }
  }
`);

export default function useTeam(id: string) {
  return useQuery(QUERY, {
    variables: {
      id,
    },
  });
}
