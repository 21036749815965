import { graphql } from '@client/graphql/types';

export const OnlineOrderBranchSettingFragment = graphql(`
    fragment OnlineOrderBranchSettingItem on OnlineOrderBranchConfiguration {
        branchID
        menuID

        pickupEnable
        autoPickupConfirm

        defaultPreparingTime

        pickupDiscountAmount
        pickupDiscountType
        
        deliveryDiscountAmount
        deliveryDiscountType

        categoryStyle

        deliveryEnable
        autoDeliveryConfirm
        deliveryRadiusLimit
        minimumOrderAmountForDelivery
        deliveryTableData {
            id
            deliveryDistance
            deliveryTime
            deliveryFee
        }
        printToKitchen
    }
`);
