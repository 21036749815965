import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useUpdateHubpage } from '@client/page/management/company-settings/logic/use-update-hubpage.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/react';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { client } from '@client/graphql/client.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useCompanyHubpageLink } from '@client/page/management/company-settings/logic/use-company-hubpage-link.ts';

interface FormValues {
  description: string | null;
  subDomain: string;
  banner: string;
}

export default function HubPageSetting() {
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const { data, refetch } = useCompanySettings(companyID);

  const form = useForm<FormValues>({
    defaultValues: {
      description: data?.company?.hubPage?.description,
      subDomain: data?.company?.hubPage?.subDomain,
      banner: data?.company?.hubPage?.banner ?? '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, control, setError, reset } = form;

  useEffect(() => {
    if (data?.company) {
      reset({
        description: data.company.hubPage?.description,
        subDomain: data.company.hubPage?.subDomain,
        banner: data.company.hubPage?.banner ?? '',
      });
    }
  }, [data, reset]);

  const [update] = useUpdateHubpage();
  const onSubmit = async (updateData: FormValues) => {
    try {
      const result = await update({
        variables: {
          companyID: companyID,
          input: {
            description: updateData.description?.trim(),
            subDomain: updateData.subDomain.trim(),
            banner: updateData.banner,
          },
        },
      });
      if (result.data) {
        await refetch();
        showAlert({
          status: 'success',
          title: t('company.setting.hubpage.update.alert.title', 'Update Success'),
          message: t('company.setting.hubpage.update.alert.message', 'Update hub page successfully'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card className="">

          <CardHeader title={'Hub Page Setting'}>
            <PrimaryButton
              id="create-button"
              data-testid="create-button"
              className="w-32"
              disabled={!form.formState.isDirty}
              onClick={() => {
                handleSubmit(onSubmit)().catch(captureException);
              }}
            >
              {t('common.button.update')}
            </PrimaryButton>
          </CardHeader>
        </Card>

        <Card>
          <CardContent>
            <form
              className={'space-y-6'}
              onSubmit={() => {
                handleSubmit(onSubmit);
              }}
            >
              <FormItem title={t('company-setting.hubpage.subdomain.title', 'Sub Domain')} className="max-w-md">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                  {...register('subDomain', {
                    required: t('company-setting.hubpage.subdomain.validate.required', 'Subdomain is required'),
                    minLength: {
                      value: 2,
                      message: t(
                        'company-setting.hubpage.subdomain.validate.min-length',
                        'Subdomain must be at least 2 characters long',
                      ),
                    },
                    maxLength: {
                      value: 10,
                      message: t(
                        'company-setting.hubpage.subdomain.validate.max-length',
                        'Subdomain must be at most 10 characters long',
                      ),
                    },
                    pattern: {
                      value: /^[a-z0-9-]+$/,
                      message: t(
                        'company-setting.hubpage.subdomain.validate.lowercase',
                        'Subdomain must be lowercase and no space',
                      ),
                    },
                    validate: (value) => {
                      if (value === 'inhouse') {
                        return t(
                          'company-setting.hubpage.subdomain.validate.special.inhouse',
                          'The subdomain you entered is a special case, please try again.',
                        );
                      }
                    },
                  })}
                  placeholder={'[placeholder].96digital.de'}
                ></input>
                {form.formState.errors.subDomain && (
                  <div className="text-red-500 text-sm">{form.formState.errors.subDomain.message}</div>
                )}
              </FormItem>
              <FormItem title={t('company-setting.hubpage.banner.title', 'Top banner')}>
                <div>
                  <Controller
                    name="banner"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <ImageInput
                          client={client}
                          images={field.value ? [field.value] : []}
                          onChange={(newValue) => {
                            if (newValue.length === 0) {
                              field.onChange(''); // Use field.onChange for proper field registration
                            } else {
                              field.onChange(newValue[0]);
                            }
                          }}
                        />
                        {error && <div className="text-red-500 text-sm">{error.message}</div>}
                      </>
                    )}
                  />
                </div>
              </FormItem>

              <FormItem title={t('company-setting.hubpage.description.title', 'Description')}
                        className="max-w-md">
            <textarea
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register('description')}
            />
                {form.formState.errors.description && (
                  <div className="text-red-500 text-sm">{form.formState.errors.description.message}</div>
                )}
              </FormItem>

              <CopyLink />

            </form>

          </CardContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}

const CopyLink = () => {
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const link = useCompanyHubpageLink();

  const copyLinkHandle = () => {
    void navigator.clipboard
      .writeText(
        // `${import.meta.env.VITE_STOREFRONT_HOST as string}/${subDomain}/${branchId}`,
        // `http://localhost:3004/${subDomain}/${branchId}`,
        link,
      )
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('company-setting.hubpage.hubpage-link.alert.message.success', 'Link copied to clipboard'),
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('company-setting.hubpage.hubpage-link.alert.message.error', 'Failed to copy link to clipboard'),
        });
      });
  };

  return (
    <FormItem title={t('Link')}>
      <SecondaryButton
        className="w-20"
        onClick={() => {
          copyLinkHandle();
        }}
      >
        {t('common.button.copy')}
      </SecondaryButton>
    </FormItem>
  );
};

