import { useParams } from 'react-router-dom';
import useNailAppointment from '@client/page/management/appointment/detail/logic/use-nail-appointment.ts';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentFragment } from '@client/graphql/nail-appointment-fragment.ts';
import { ContentLayout, FullPrimaryContent, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import moment from 'moment';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import {
  AppointmentConfirmationStatusSelection,
} from '@client/page/management/appointment/detail/component/appointment-confirmation-status-selection.tsx';
import CustomerHistory from '@client/page/management/appointment/detail/component/customer-history.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import UpdateAppointment from '@client/page/management/appointment/detail/component/update-appointment.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useContext } from 'react';
import { CustomLocationContext } from '@client/app/research/custom-location-provider.tsx';
import { useTranslate } from '@tolgee/react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

export default function AppointmentDetail() {
  const appointmentId = useParams().appointmentId ?? '';
  // const branchId = useFocusedBranchContext().branch?.id ?? '';
  const dialogManager = useModalManagerContext();
  // const { showAlert } = useNotificationCenter();
  const previousPath = useContext(CustomLocationContext) ?? '';
  const { t } = useTranslate();

  const { data, refetch } = useNailAppointment(appointmentId);
  // const { data: teamListData } = useGetBranchTeam(branchId);
  // const [switchTeam] = useSwitchTeamForAppointment();
  // const [assignEmployee] = useAssignEmployeeForAppointment();

  const appointmentData = getFragmentData(NailAppointmentFragment, data?.nailAppointment);
  // const teamData = getFragmentData(PickedNailAppointmentTeamFragment, appointmentData?.teams);
  // const employee = getFragmentData(PickedNailAppointmentEmployeeFragment, appointmentData?.employees);

  if (!appointmentData) {
    return <>Appoiment not found</>;
  }

  const refetchData = () => {
    void refetch();
  };

  //TODO hide for now
  // const handleSwitchTeam = () => {
  //   dialogManager.showDialog((onClose) => {
  //     const onSubmit = (teamId: string) => {
  //       switchTeam({
  //         variables: {
  //           appointmentId: appointmentId,
  //           teamId: teamId,
  //         },
  //       })
  //         .then(() => {
  //           onClose();
  //           showAlert({
  //             status: 'success',
  //             title: 'Update success',
  //             message: 'Switch team successful',
  //           });
  //           void refetch();
  //         })
  //         .catch((e) => void e);
  //     };
  //     return (
  //       <Card>
  //         <CardHeader title="Switch team" />
  //         <CardContent>
  //           <div className="text-lg font-medium mb-2">Team list</div>
  //           <div className="text-sm italic text-gray-400 mb-4">Click to switch, this action can be undone</div>
  //           <div className="flex flex-col gap-1.5">
  //             {teamListData?.teams.map((teamFragment) => {
  //               const team = getFragmentData(TeamFragment, teamFragment);
  //               return (
  //                 <div
  //                   className="border rounded-md py-1.5 px-2 bg-gray-50 hover:bg-white"
  //                   onClick={() => {
  //                     onSubmit(team.id);
  //                   }}
  //                   key={team.id}
  //                 >
  //                   {team.name}
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </CardContent>
  //         <CardFooter>
  //           <SecondaryButton>Cancel</SecondaryButton>
  //         </CardFooter>
  //       </Card>
  //     );
  //   });
  // };

  const handleUpdate = () => {
    dialogManager.showDialog((onClose) => <UpdateAppointment appointmentData={appointmentData} onClose={onClose} />);
  };

  // TODO hide for now
  // const handleAssignEmployee = (userId: string) => {
  //   assignEmployee({
  //     variables: {
  //       appointmentId: appointmentId,
  //       userId: userId,
  //     },
  //   })
  //     .then(() => {
  //       void refetch();
  //     })
  //     .catch((e) => void e);
  // };

  const ListAndDashboardPathSuffix =
    `?startTime=${appointmentData.startTime ?? moment().format('YYYY-MM-DD HH:mm')}` +
    `&endTime=${appointmentData.endTime ?? moment().format('YYYY-MM-DD HH:mm')}`;

  const historyPathSurfix = `?nearestDetailTime=${appointmentData.startTime ?? moment().format('YYYY-MM-DD HH:mm')}`;

  const backTarget = previousPath.includes('dashboard')
    ? `/dashboard` + ListAndDashboardPathSuffix
    : previousPath.includes('list')
      ? `/appointment/list` + ListAndDashboardPathSuffix
      : '/appointment/history' + historyPathSurfix;


  const cancelReasonString = appointmentData.cancelReason?.reason.map(reason =>
    t('appointment.cancel-reason.' + String(reason).toLowerCase(), String(reason).toLowerCase())).join(', ') ?? '';

  return (
    <div className="space-y-4">
      <div className="flex flex-col xl:flex-row gap-4">
        <ContentLayout className="xl:basis-2/3">
          <FullPrimaryContent>
            <Card>
              <CardHeader
                title={t('appointment.appointment-detail.header', 'Appointment')}
                withBackButton={true}
                backTarget={backTarget}
              >
                <PrimaryButton
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  {t('appointment.appointment-detail.button.edit-confirm', 'Update')}
                </PrimaryButton>
              </CardHeader>
              <CardContent>
                <div className="space-y-8">
                  <AppointmentConfirmationStatusSelection
                    appointmentId={appointmentId}
                    status={appointmentData.confirmationStatus}
                    refetchData={refetchData}
                  />
                  {appointmentData.cancelReason?.reason && (
                    <AppointmentItem
                      label={t('appointment.appointment-detail.cancel-reason.title', 'Cancel reason')}
                      value={cancelReasonString}
                    />
                  )}
                  {appointmentData.cancelReason?.note && (
                    <AppointmentItem
                      label={t('appointment.appointment-detail.cancel-reason.note', 'Note')}
                      value={appointmentData.cancelReason.note} />
                  )}
                  <AppointmentItem
                    label={t('appointment.appointment-detail.service.title', 'Service')}
                    value={
                      appointmentData.products[0]
                        ? appointmentData.products[0].pickedProduct.title
                        : t('appointment.appointment-detail.service.empty', 'Empty')
                    }
                  />
                  <AppointmentItem
                    label={t('appointment.appointment-detail.guest-name.title', 'Name')}
                    value={
                      appointmentData.customerVariant.name !== ''
                        ? appointmentData.customerVariant.name
                        : t('appointment.appointment-detail.name.empty', 'Empty')
                    }
                  />
                  {appointmentData.message != '' && (
                    <AppointmentItem
                      label={t('appointment.appointment-detail.message.title', 'Message')}
                      value={appointmentData.message ?? ''}
                    />
                  )}
                  <AppointmentTimeItem startTime={appointmentData.startTime ?? ''} />
                </div>
              </CardContent>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        {/*// TODO hide for now */}
        {/*<div>*/}
        {/*  <div className="xl:basic-1/3 2xl:min-w-96">*/}
        {/*    {teamData && (*/}
        {/*      <PickedNailApointmentTeam*/}
        {/*        team={teamData[0]}*/}
        {/*        employees={employee}*/}
        {/*        switchTeam={handleSwitchTeam}*/}
        {/*        assignEmployee={handleAssignEmployee}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <ContentLayout>
        <PrimaryContent>
          <div className="space-y-4">
            {(appointmentData.customerVariant.email || appointmentData.customerVariant.phone) && (
              <Card>
                <CardHeader title={t('appointment.appointment-detail.contact-detail.title', 'Contact detail')} />
                <CardContent>
                  <div className="space-y-8">
                    <AppointmentItem
                      label={t('appointment.appointment-detail.email.title', 'Email')}
                      value={appointmentData.customerVariant.email}
                    />
                    <AppointmentItem
                      label={t('appointment.appointment-detail.phone.title', 'Phone')}
                      value={appointmentData.customerVariant.phone}
                    />
                  </div>
                </CardContent>
              </Card>
            )}
            <CustomerHistory
              appointmentId={appointmentData.id}
              customerId={appointmentData.customerVariant.customerId ?? ''}
              currentAppointmentTime={appointmentData.startTime ?? ''}
            />
          </div>
        </PrimaryContent>
      </ContentLayout>
    </div>
  );
}

function AppointmentItem(props: {
  label: string;
  value: string;
  className?: string;
  href?: string
  action?: string
}) {
  return (
    <div className={props.className}>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="flex-1">
          <p className="mb-2 text-sm text-gray-500">{props.label}</p>
          <a href={props.href}
             className="text-lg ml-0.5 md:max-w-md line-clamp-2 break-all text-ellipsis">{props.value}</a>
        </div>
        {props.action && (
          <div className="mt-2 md:mt-0 md:w-auto">
            <a href={props.href}>
              <SecondaryButton>{props.action}</SecondaryButton>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

function AppointmentTimeItem(props: { startTime: string }) {
  const { startTime } = props;
  const company = useFocusedCompany();
  const { t } = useTranslate();

  return (
    <div className="flex max-w-md gap-4">
      <div>
        <p className="mb-2 text-sm text-gray-500">
          {t('appointment.appointment-detail.booking-date.title', 'Booking date')}
        </p>
        <p className="py-1.5 px-3 rounded-md border border-gray-300">
          {moment(startTime).tz(company.settings.timezone).format('ddd DD MMMM')}
        </p>
      </div>

      <div>
        <p className="mb-2 text-sm text-gray-500">{t('appointment.appointment-detail.booking-time.title', 'Email')}</p>
        <p className="py-1.5 px-3 rounded-md border border-gray-300">
          {moment(startTime).tz(company.settings.timezone).format('HH:mm')}
        </p>
      </div>
    </div>
  );
}
