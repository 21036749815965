import { useNavigate, useParams } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { useCallback, useEffect } from 'react';
import Loading from '@packages/ui/loading.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import Card from '@packages/ui/card/card.tsx';
import { Helmet } from 'react-helmet-async';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useGetService } from '@client/page/management/appointment/service/service-detail/logic/use-get-service.ts';
import EditMenuButton from '@client/page/management/menu/menu-detail/component/menu-edit-button.tsx';
import EditMenuTitleModal from '@client/page/management/menu/menu-detail/component/menu-update-title.tsx';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import useDeleteMenu from '@client/page/management/menu/menu-detail/logic/use-delete-menu.ts';
import { useForm } from 'react-hook-form';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import ServiceTable from './component/service-table';

interface FormValues {
  title: string;
}

export default function ServiceMenuDetail() {
  const serviceId = useParams().serviceId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { data, loading, refetch, error } = useGetService(serviceId);
  const { showAlert } = useNotificationCenter();

  const form = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { getValues, reset } = form;
  useEffect(() => {
    if (data?.serviceMenu) {
      reset({
        title: data.serviceMenu.title,
      });
    }
  }, [data, reset]);

  const dialog = useDialog();
  const [deleteMenu] = useDeleteMenu();
  const showUpdateTitle = () => {
    dialog.showComponent((onClose) => {
      return <EditMenuTitleModal title={getValues('title')} onClose={onClose} refetch={refetch} />;
    });
  };
  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('service.service-detail.extra-action.delete.alert.title'),
      message: t('service.service-detail.extra-action.delete.alert.message'),
      textButton: t('service.service-detail.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenu({ variables: { id: serviceId } });
        showAlert({
          status: 'success',
          title: t('service.service-detail.notification.success.title', 'Delete successful'),
          message: t('service.service-detail.notification.success.message', 'Menu has been deleted successfully'),
        });
        navigate('..');
      },
    });
  }, [deleteMenu, dialog, navigate, serviceId, showAlert, t]);

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: { breadcrumbName: data?.serviceMenu.title },
    });
  }, [navigate, data?.serviceMenu.title]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title={error.name} message={error.message} />;
  }

  if (!data) {
    return <Card>No data</Card>;
  }

  return (
    <div>
      <ContentLayout>
        <Helmet>
          <title>{t('service.service-detail.helmet.title')}</title>
          <meta name="description" content="This page displays the details of a service menu"></meta>
        </Helmet>
        <PrimaryContent>
          <div className="flex-col space-y-4">
            <Card>
              <CardHeader title={data.serviceMenu.title} withBackButton={true}>
                <SecondaryButton
                  onClick={() => {
                    navigate(`sections/create`);
                  }}
                >
                  {t('service.service-detail.table-product.button.add-category', 'Add category')}
                </SecondaryButton>

                <PrimaryButton
                  onClick={() => {
                    navigate('products/create');
                  }}
                >
                  {t('service.service-detail.table-product.button.add-product', 'Add service')}
                </PrimaryButton>
                <EditMenuButton deleteMenu={onDestroy} editTitle={showUpdateTitle} />
              </CardHeader>

              <CardTableContent>
                <ServiceTable products={data.serviceMenu.menuProducts} sections={data.serviceMenu.sections} />
              </CardTableContent>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
    </div>
  );
}
