import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const CREATE_SERVICE_MENU_SECTION = graphql(`
  mutation createServiceMenuSection(
    $menuId: ID!
    $input: CreateServiceMenuSection!
    $timeRuleInput: CreateMenuSectionTimeRule!
  ) {
    createServiceMenuSection(menuId: $menuId, input: $input, timeRuleInput: $timeRuleInput) {
      id
      name
    }
  }
`);

export function useCreateServiceMenuSection() {
  return useMutation(CREATE_SERVICE_MENU_SECTION, {});
}
