import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useNavigate } from 'react-router-dom';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import TableReservationTableList from '@client/page/management/table-reservation/list/component/reservation-table-list.tsx';
import { useTableReservations } from '@client/page/management/table-reservation/list/logic/use-table-reservations.ts';
import { ReactNode } from 'react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import TableReservationListStats from '@client/page/management/table-reservation/list/component/table-reservation-list-stats.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PauseToggleInput from '../setting/component/pause-toggle-input';

const TableReservationList = (props: {
  withTitle?: boolean;
  startTime: Dayjs;
  endTime: Dayjs;
  header?: ReactNode;
  footer?: ReactNode;
  navigationPrefix?: string;
}) => {
  const { startTime, endTime, header, footer } = props;

  // Hook
  const navigate = useNavigate();
  const { t } = useTranslate();

  // Params
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  // Fetch
  const { data: reservations, loading } = useTableReservations(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });

  const handleCreateClick = () => {
    if (props.navigationPrefix) {
      navigate(`${props.navigationPrefix}/create`, {
        state: { startTime: startTime.format('YYYY-MM-DDTHH:mm'), endTime: endTime.format('YYYY-MM-DDTHH:mm') },
      });
    } else {
      navigate('create', {
        state: { startTime: startTime.format('YYYY-MM-DDTHH:mm'), endTime: endTime.format('YYYY-MM-DDTHH:mm') },
      });
    }
  };

  return (
    <Card>
      <Helmet>
        <title>Reservation schedule</title>
      </Helmet>
      <CardHeader title={t('reservation.schedule.title')}>
        {header}

        {startTime.isSame(dayjs(), 'day') && endTime.isSame(dayjs(), 'day') && <PauseToggleInput />}
        <PrimaryButton
          onClick={() => {
            handleCreateClick();
          }}
          data-testid="list-create-button"
        >
          <span>{t('reservation.schedule.button.create', 'Create')}</span>
        </PrimaryButton>
      </CardHeader>

      <CardTableContent>
        <TableReservationListStats loading={loading} items={reservations} />
        <TableReservationTableList
          loading={loading}
          items={reservations}
          onClick={(id) => {
            if (props.navigationPrefix) {
              navigate(`${props.navigationPrefix}/${id}`);
            } else {
              navigate(id);
            }
          }}
        />
      </CardTableContent>

      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};

export default TableReservationList;
