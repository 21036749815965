import { useEffect, useState } from 'react';
import { NailAppointmentFilter, NailAppointmentItemFragment, SubscribeEvent } from '@client/graphql/types/graphql.ts';
import { getFragmentData } from '@client/graphql/types';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useNailAppointmentsQuery from '@client/page/management/appointment/list/logic/use-nail-appointments-query.ts';
import { NailAppointmentFragment } from '@client/graphql/nail-appointment-fragment.ts';
import useAppointmentUpdateSubscribe from '@client/page/management/appointment/list/logic/use-appointment-update-subscribe.ts';

const notificationAudio = new Audio('https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/notification.wav');

export const useNailAppointments = (branchID: string, filter: NailAppointmentFilter) => {
  const { showAlert } = useNotificationCenter();

  const [data, setData] = useState<NailAppointmentItemFragment[]>([]);

  const { data: _initialData, loading, refetch, error } = useNailAppointmentsQuery(branchID, filter);
  const { data: _updateData } = useAppointmentUpdateSubscribe(branchID);

  useEffect(() => {
    if (!_initialData) {
      return;
    }

    setData(
      _initialData.nailAppointments.map((item) => {
        return getFragmentData(NailAppointmentFragment, item);
      })
    );
  }, [_initialData]);

  useEffect(() => {
    if (!_updateData) {
      return;
    }

    const nailAppointment = getFragmentData(
      NailAppointmentFragment,
      _updateData.appointmentUpdateSubscribe.appointment
    );

    if (_updateData.appointmentUpdateSubscribe.event === SubscribeEvent.Create) {
      showAlert({
        status: 'success',
        title: 'New appointment',
        message: `New appointment has been created for ${nailAppointment.customerVariant.name}`,
      });

      void notificationAudio.play();
    }

    refetch().catch((err: Error) => {
      showAlert({
        status: 'error',
        title: 'Error',
        message: err.message,
      });
    });
  }, [_updateData, refetch, showAlert]);

  return { data, loading, error, showAlert };
};
