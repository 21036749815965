import useInitAppointmentConfiguration from '@client/page/management/appointment/configuration/logic/use-initialize-appointment-configuration.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';

export default function AppointmentBranchConfigurationSetup() {
  const branch = useFocusedBranchContext();
  const [Initialize] = useInitAppointmentConfiguration(branch.branch?.id ?? '');

  const onSubmit = () => {
    void Initialize();
  };

  return (
    <div className="w-full h-[75vh] flex justify-center items-center">
      <Card>
        <CardHeader title="Setup appointment configuration" />

        <CardContent>
          <p className="text-lg">
            Your configuration has not been set up yet, please click on setup button to initialize
          </p>
        </CardContent>

        <CardFooter>
          <PrimaryButton onClick={onSubmit} className="px-8 py-2">
            Setup
          </PrimaryButton>
        </CardFooter>
      </Card>
    </div>
  );
}
