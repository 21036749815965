import { OpeningTimeServiceType } from '@client/graphql/types/graphql';
import OpeningTimeSetup from './opening-time-setup';

const OpeningTimeSetupPage = (props: {title: string, service: OpeningTimeServiceType}) => {
    return (
        <OpeningTimeSetup
            title={props.title}
            service={props.service}
        />
    );
};

export default OpeningTimeSetupPage;


