import { useQuery } from '@apollo/client';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { graphql } from '@client/graphql/types';
import { MenuSource } from '@client/graphql/types/graphql.ts';

export const MENU_LIST_QUERY = graphql(`
  query MenuList($companyId: ID!, $menuSource: MenuSource!) {
    menus(companyId: $companyId, menuSource: $menuSource) {
      id
      title
    }
  }
`);

export function useMenuList(menuSource: MenuSource) {
  const companyId = useCompanyId();

  return useQuery(MENU_LIST_QUERY, {
    variables: { companyId, menuSource },
  });
}
