import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation createAppointment(
    $branchId: ID!
    $teamId: ID!
    $userId: ID!
    $input: CreateAppointmentInput!
    $pickedProductInput: [PickedProductInput!]!
  ) {
    createAppointment(
      branchId: $branchId
      teamId: $teamId
      userId: $userId
      input: $input
      pickedProductInput: $pickedProductInput
    ) {
      id
    }
  }
`);
export default function useCreateAppointment() {
  return useMutation(MUTATION, {});
}
