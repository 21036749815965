import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '@client/module/auth/auth-context.ts';

export default function useCheckAdminRole() {
  const { auth } = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    auth?.role.includes('ADMIN') ? setIsAdmin(true) : setIsAdmin(false);
    auth?.role.includes('SUPER_ADMIN') ? setIsSuperAdmin(true) : setIsSuperAdmin(false);
  }, [auth?.role]);

  return useMemo(
    () => ({
      isAdmin,
      isSuperAdmin,
    }),
    [isAdmin, isSuperAdmin]
  );
}
