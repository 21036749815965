import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation updateTeam($id: ID!, $input: UpdateTeamInput!, $memberTeam: [TeamMemberUpdateInput!]!) {
    updateTeam(id: $id, input: $input, memberTeam: $memberTeam) {
      id
      name
      description
    }
  }
`);

export default function useUpdateTeam() {
  return useMutation(MUTATION, {});
}
