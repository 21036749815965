import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';
export const HOLIDAY_OVERLAP_SERVICE = graphql(`
  query checkOverlapService($branchId: ID!, $input: CheckOverlapServiceInput!) {
    checkOverlapService(branchId: $branchId, input: $input) {
      reservation
      appointment
      onlineOrder
    }
  }
`);
export default function useCheckOverlapService() {
  return useQuery(HOLIDAY_OVERLAP_SERVICE, {});
}
