import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CREATE_SERVICE_PRODUCT = graphql(`
  mutation CreateAppointmentMenuProduct(
    $menuID: ID!
    $input: CreateAppointmentMenuProductInput!
    $teamIds: [String!]!
  ) {
    createAppointmentMenuProduct(menuID: $menuID, input: $input, teamIds: $teamIds) {
      id
    }
  }
`);

export default function useCreateServiceMenuProduct() {
  return useMutation(CREATE_SERVICE_PRODUCT, {});
}
