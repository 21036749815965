import ApiKeyList from '@client/page/api-key/internal/list/api-key-list.tsx';

export const ApiKeyRouter = {
  path: 'api-key',
  handle: {
    crumb: () => 'All keys',
  },
  children: [
    {
      path: 'internal',
      element: <ApiKeyList />,
    },
  ],
};
