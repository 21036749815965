import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import classNames from 'classnames';
import moment from 'moment-timezone';

export default function HolidayDatePicker(props: {
  format: 'date' | 'hour';
  time: string;
  setTime: (dateString: string) => void;
}) {
  const [selectedDate, setSelectedDate] = useState(() => moment(props.time).utc(true));

  // Cập nhật selectedDate khi props.time thay đổi
  useEffect(() => {
    setSelectedDate(moment(props.time).utc(true));
  }, [props.time, setSelectedDate]);

  const format = props.format == 'date' ? 'DD MMMM' : 'HH:mm';
  return (
    <DatePicker
      toggleCalendarOnIconClick
      {...(props.format === 'hour' && { showTimeSelectOnly: true, showTimeSelect: true })}
      value={selectedDate.format(format)}
      dateFormat={format}
      className={classNames('border-gray-300 rounded-md z-50', props.format == 'hour' ? 'max-w-20 text-center' : '')}
      onChange={(date) => {
        if (!date) {
          return;
        }
        setSelectedDate(moment(date).utc(true));
        props.setTime(date.toISOString());
      }}
    />
  );
}
