import Dashboard from '@client/page/dashboard/dashboard.tsx';
import OnlineOrderDetail from '@client/page/management/online-order/detail/online-order-detail.tsx';
import TableReservationDetail from '@client/page/management/table-reservation/detail/table-reservation-detail.tsx';
import TableReservationCreate from '@client/page/management/table-reservation/create/table-reservation-create.tsx';
import { Navigate } from 'react-router-dom';
import AppointmentDetail from '@client/page/management/appointment/detail/appointment-detail.tsx';
import DashboardCreateRoute from '@client/page/dashboard/dashboard-create-route.tsx';

export const DashboardRouter = {
  path: 'dashboard',
  children: [
    { path: '', element: <Dashboard /> },
    {
      path: 'online-order/:onlineOrderID',
      element: <OnlineOrderDetail />,
    },
    {
      path: 'appointment',
      children: [
        {
          path: ':appointmentId',
          element: <AppointmentDetail />,
        },
      ],
    },
    {
      path: 'table-reservation',
      children: [
        {
          path: '',
          element: <Navigate to=".." />,
        },
        {
          path: ':id',
          element: <TableReservationDetail />,
        },
        {
          path: 'create',
          element: <TableReservationCreate />,
        },
      ],
    },
    {
      path: 'create',
      element: <DashboardCreateRoute />,
    },
  ],
};
