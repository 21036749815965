import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import FooterBar from '@client/page/component/footer-bar.tsx';
import { MobilePanel } from './component/mobile-panel.tsx';
import DesktopPanel from './component/desktop-panel.tsx';
import NavigationBar from './component/navigation-bar.tsx';
import NavigationContent from './component/navigation-content.tsx';

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <MobilePanel
          show={sidebarOpen}
          onClose={setSidebarOpen}
          onClick={() => {
            setSidebarOpen(false);
          }}
        >
          <NavigationContent
            closeSidebar={() => {
              setSidebarOpen(false);
            }}
          />
        </MobilePanel>

        {/* Static sidebar for desktop */}
        <DesktopPanel>
          <NavigationContent />
        </DesktopPanel>

        <div className="lg:pl-72">
          <NavigationBar setSidebarOpen={setSidebarOpen} />
          <main className="bg-gray-100 min-h-screen flex flex-col sm:mx-8 mx-2 mt-2">

            <div className="flex-grow">
              <Outlet />
            </div>

            <FooterBar />
          </main>
        </div>
      </div>
    </>
  );
}
