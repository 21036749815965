import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation InitiateAppointmentBranchConfiguration($branchId: ID!) {
    initiateAppointmentBranchConfiguration(branchId: $branchId) {
      branchId
      enable
    }
  }
`);

export default function useInitAppointmentConfiguration(branchId: string) {
  return useMutation(MUTATION, {
    variables: {
      branchId,
    },
    refetchQueries: ['AppointmentBranchConfiguration'],
  });
}
