import { NavigationItem } from '@client/app/component/navigation-content.tsx';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

function NavigationItemButton(props: {
  item: NavigationItem;
  featTitle: string;
  onClick: () => void;
  currentLocation: (path: string) => boolean;
}) {
  const item = props.item;
  const { t } = useTranslate();
  return (
    <li className="ml-4">
      <Link
        to={item.href}
        onClick={props.onClick}
        className={classNames(
          props.currentLocation(item.href) ? 'bg-gray-500 text-white' : 'text-white hover:text-white hover:bg-gray-500',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold max-w-56'
        )}
      >
        <item.icon
          className={classNames(
            props.currentLocation(item.href) ? 'text-white' : 'text-gray-500 group-hover:text-white',
            'h-6 w-6 shrink-0'
          )}
          aria-hidden="true"
        />
        <span> {t('navigation-content.' + props.featTitle + '.item.' + item.title, item.title)}</span>
      </Link>
    </li>
  );
}

export default NavigationItemButton;
