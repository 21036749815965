import { graphql } from '../graphql/types';

export const MenuProductFragment = graphql(`
  fragment MenuProductItem on MenuProduct {
    id
    code
    title
    priceMode
    description
    images
    ingredients
    labels
    priceMode
    allergics
    enabled
    section {
      ...MenuSectionItem
    }
    configurations {
      id
      title
      type
      values {
        ...MenuProductConfigurationValueItem
      }
    }

    menuId
    sectionId
  }
`);
