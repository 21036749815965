import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatGraphQlError, validationNestedErrors } from '@client/module/error/error';
import CardContent from '@packages/ui/card/card-content';
import Center from '@packages/ui/center';
import Spinner from '@packages/ui/spinner';
import FormLayout from '@packages/ui/form/form-layout';
import TextInput from '@packages/ui/form/input/text-input';
import FormItem from '@packages/ui/form/form-item';
import Card from '@packages/ui/card/card';
import CardHeader from '@packages/ui/card/card-header';
import CardFooter from '@packages/ui/card/card-footer';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import MenuSectionSelection from '@client/page/management/menu/menu-section/menu-section-selection-input/menu-section-selection.tsx';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import { ProductConfigurationType } from '@client/graphql/types/graphql';
import { Controller, useForm } from 'react-hook-form';
import { captureException } from '@sentry/react';
import { ApolloError } from '@apollo/client';
import { currencySymbol, formatRawCurrency, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useUpdateServiceProduct from '@client/page/management/appointment/service/service-product/service-product-edit/logic/use-update-service-product.ts';
import { useGetServiceProduct } from '@client/page/management/appointment/service/service-product/service-product-edit/logic/use-get-service-product.ts';

interface MenuProductForm {
  title: string;
  description: string;
  sectionId: string | null;
  price: number;
  duration: string;
  teamIds: string[];
}

const DESCRIPTION_MAX_LENGTH = 200;

export default function ServiceProductEdit() {
  const productId = useParams().productId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const currency = useCompanyCurrency();

  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm<MenuProductForm>({
    defaultValues: {
      title: '',
      description: '',
      sectionId: null,
      price: 0,
      duration: '0',
      teamIds: [],
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { data, loading, refetch } = useGetServiceProduct(productId);
  const baseConfig = data?.appointmentMenuProduct.configurations.find(
    (config) => config.type === ProductConfigurationType.Base
  );
  const basePrice = baseConfig?.values[0].price;
  const [update] = useUpdateServiceProduct();
  // const [assignTeam] = useAssignTeam();
  // const [deleteTeam] = useDeleteTeam();

  useEffect(() => {
    if (data) {
      reset({
        title: data.appointmentMenuProduct.title,
        description: data.appointmentMenuProduct.description,
        sectionId: data.appointmentMenuProduct.sectionId,
        price: Number(formatRawCurrency(basePrice ?? 0, currency).value),
        duration: data.appointmentMenuProduct.appointmentMenuProductData.duration.toString(),
        teamIds: data.appointmentMenuProduct.appointmentMenuProductData.nailAppointmentMenuProductTeams.map(
          (team) => team.teamId
        ),
      });
    }
  }, [basePrice, data, data?.appointmentMenuProduct, currency, reset]);

  const onSubmit = async (data: MenuProductForm) => {
    try {
      const result = await update({
        variables: {
          id: productId,
          input: {
            duration: parseInt(data.duration),
            menuProduct: {
              title: data.title,
              description: data.description,
              enabled: true,
              sectionID: data.sectionId,
              configurations: [
                {
                  id: baseConfig?.id ?? '',
                  title: 'Base',
                  type: ProductConfigurationType.Base,
                  values: [
                    {
                      id: baseConfig?.values[0].id ?? '',
                      name: 'Base',
                      price: normalizeCurrency(String(data.price), currency),
                    },
                  ],
                },
              ],
            },
          },
        },
      });
      await refetch();
      if (result.data?.updateAppointmentMenuProduct) {
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'New menu product has been created',
        });
        const sectionId = getValues('sectionId');
        reset(undefined, { keepDefaultValues: true });
        setValue('sectionId', sectionId);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationNestedErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof MenuProductForm,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  if (!data) {
    if (loading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    } else {
      return <div>Product not found</div>;
    }
  }

  const handlePriceInput = (price: number) => {
    if (isNaN(price)) {
      return 0;
    }
    return String(price);
  };
  //TODO hide for now
  // const handleTeam = (checked: boolean, id: string) => {
  //   const checkedTeam = data.appointmentMenuProduct.appointmentMenuProductData.nailAppointmentMenuProductTeams.find(
  //     (item) => item.teamId == id
  //   );
  //   if (checked) {
  //     assignTeam({
  //       variables: {
  //         teamId: id,
  //         serviceProductId: data.appointmentMenuProduct.id,
  //       },
  //     })
  //       .then(() => {
  //         void refetch();
  //       })
  //       .catch((e) => {
  //         void e;
  //       });
  //     return;
  //   }
  //
  //   deleteTeam({
  //     variables: {
  //       id: checkedTeam?.id ?? '',
  //     },
  //   })
  //     .then(() => {
  //       // setValue(
  //       //   'teamIds',
  //       //   getValues('teamIds').filter((item) => item !== id)
  //       // );
  //       void refetch();
  //     })
  //     .catch((e) => {
  //       void e;
  //     });
  // };

  return (
    <>
      <div className="grid grid-cols-1  xl:grid-cols-7 w-full  gap-4">
        <ContentLayout className="xl:col-span-4">
          <Helmet>
            <title>{t('service.service-detail.product.edit.helmet.title', 'Update Product')}</title>
            <meta name="description" content="This page allows you to update a new product" />
          </Helmet>
          <FullPrimaryContent>
            <Card>
              <CardHeader title={t('service.service-detail.product.edit.header', 'Edit Service`s Product')} />
              <CardContent>
                <FormLayout>
                  <FormItem className="max-w-lg" title={''}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="sectionId"
                      render={({ field: { onChange, value } }) => (
                        <MenuSectionSelection
                          menuId={data.appointmentMenuProduct.menuId}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    className="max-w-lg sm:col-span-3"
                    title={t('service.service-detail.product.edit.form-input.name.title', 'Title')}
                  >
                    <Controller
                      rules={{
                        required: t(
                          'service.service-detail.product.edit.form-input.name.validate.required',
                          'Name is required'
                        ),
                        minLength: {
                          value: 3,
                          message: t(
                            'service.service-detail.product.edit.form-input.name.validate.min-length',
                            'Min length is 3'
                          ),
                        },
                        maxLength: {
                          value: 50,
                          message: t(
                            'service.service-detail.product.edit.form-input.name.validate.max-length',
                            'Max length is 50'
                          ),
                        },

                        pattern: {
                          value: /^\S.*\S$/,
                          message: t(
                            'service.service-detail.product.edit.form-input.name.validate.no-whitespace',
                            'product title must not start or end with whitespace'
                          ),
                        },
                      }}
                      defaultValue={''}
                      control={control}
                      name="title"
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          data-testid="title-input"
                          type="text"
                          label="title"
                          name=""
                          placeholder={t('service.service-detail.product.edit.form-input.name.placeholder', 'Name')}
                          value={value}
                          error={errors.title?.message}
                          autoComplete={'off'}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    subtitle={`(${String(watch('description').length)}/${String(DESCRIPTION_MAX_LENGTH)})`}
                    className="max-w-lg"
                    title={t('service.service-detail.product.edit.form-input.description.title', 'Description')}
                  >
                    <Controller
                      control={control}
                      name="description"
                      defaultValue={''}
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36"
                          placeholder={t(
                            'service.service-detail.product.edit.form-input.description.placeholder',
                            'Description'
                          )}
                          value={value}
                          name=""
                          onChange={onChange}
                          maxLength={DESCRIPTION_MAX_LENGTH}
                        />
                      )}
                    />
                    {errors.description && (
                      <p className="mt-1 text-sm text-red-600" id="email-error">
                        {errors.description.message}
                      </p>
                    )}
                  </FormItem>

                  <div className="grid grid-cols-2 max-w-sm gap-4">
                    <FormItem
                      className="max-w-lg sm:col-span-1"
                      title={t('service.service-detail.product.edit.form-input.price.title', 'Price')}
                    >
                      <Controller
                        control={control}
                        rules={{
                          required: t(
                            'service.service-detail.product.edit.form-input.price.validate.required',
                            'Price is required'
                          ),
                        }}
                        name="price"
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            data-testid=""
                            type="number"
                            label=""
                            name=""
                            placeholder={t('service.service-detail.product.edit.form-input.price.placeholder', 'Price')}
                            value={handlePriceInput(value)}
                            error={errors.price?.message}
                            autoComplete={'off'}
                            onChange={onChange}
                            suffix={<span className="text-gray-500">{currencySymbol(currency)}</span>}
                          />
                        )}
                      />
                    </FormItem>
                    <FormItem
                      className="max-w-lg sm:col-span-1"
                      title={t('service.service-detail.product.edit.form-input.duration.title', 'Duration')}
                    >
                      <Controller
                        control={control}
                        rules={{
                          required: t(
                            'service.service-detail.product.edit.form-input.duration.validate.required',
                            'Duration is required'
                          ),
                        }}
                        name="duration"
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            data-testid="code-input"
                            type="number"
                            label="code"
                            name=""
                            placeholder={t(
                              'service.service-detail.product.edit.form-input.duration.placeholder',
                              'Duration'
                            )}
                            value={value}
                            error={errors.duration?.message}
                            autoComplete={'off'}
                            onChange={onChange}
                            suffix={
                              <span className="text-gray-500">
                                {t('service.service-detail.product.edit.form-input.duration.minutes', 'Minutes')}
                              </span>
                            }
                          />
                        )}
                      />
                    </FormItem>
                  </div>
                </FormLayout>
              </CardContent>
              <CardFooter>
                <div className="flex justify-center space-x-4 w-96">
                  <SecondaryButton
                    className="flex-1"
                    onClick={() => {
                      navigate('../..');
                    }}
                  >
                    {t('common.button.cancel')}
                  </SecondaryButton>
                  <PrimaryButton
                    data-testid="update-button"
                    className="w-32 flex-1"
                    onClick={() => {
                      clearErrors();
                      handleSubmit(onSubmit)().catch(captureException);
                      navigate('./');
                    }}
                    loading={loading}
                  >
                    {t('common.button.update')}
                  </PrimaryButton>
                </div>
              </CardFooter>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div className="xl:col-span-2 xl:max-w-[450px]">
          {/*<ServiceProductTeam teamIds={watch('teamIds')} onClick={handleTeam} />*/}
        </div>
      </div>
    </>
  );
}
