import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ArchiveBoxXMarkIcon, EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { useTranslate } from '@tolgee/react';
import useDeleteMenu from '@client/page/management/menu/menu-detail/logic/use-delete-menu.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import EditMenuTitleModal from '@client/page/management/menu/menu-detail/component/menu-update-title.tsx';
import useDeleteAllMenuProduct from '@client/page/management/menu/menu-detail/logic/use-delete-all-menu-product.ts';
import useCheckAdminRole from '@client/module/auth/check-role-hook.ts';

export default function EditMenuButton(props: { menuId: string }) {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { showAlert } = useNotificationCenter();
  const dialog = useDialog();
  const { isSuperAdmin } = useCheckAdminRole();

  const [deleteMenu] = useDeleteMenu();
  const [deleteAllProducts] = useDeleteAllMenuProduct();

  const onDeleteMenu = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenu({ variables: { id: props.menuId } });
        showAlert({
          status: 'success',
          title: t('menu.menu-detail.notification.success.title', 'Delete successful'),
          message: t('menu.menu-detail.notification.success.message', 'Menu has been deleted successfully'),
        });
        navigate('..');
      },
    });
  }, [deleteMenu, dialog, navigate, props.menuId, showAlert, t]);

  const onDeleteMenuProduct = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.extra-action.delete-product.alert.title', 'Delete all menu products'),
      message: t(
        'menu.menu-detail.extra-action.delete-product.alert.message',
        'Are you sure? This action cannot be undone.'
      ),
      textButton: t('common.button.delete', 'Delete'),
      onConfirm: async () => {
        await deleteAllProducts({ variables: { menuId: props.menuId } });
        showAlert({
          status: 'success',
          title: t('menu.menu-detail.delete-product.notification.success.title', 'Delete successful'),
          message: t(
            'menu.menu-detail.delete-product.notification.success.message',
            'All menu products have been deleted successfully'
          ),
        });
        navigate('..');
      },
    });
  }, [dialog, navigate, props.menuId, showAlert, t, deleteAllProducts]);

  const showUpdateTitle = () => {
    dialog.showComponent((onClose) => {
      return <EditMenuTitleModal onClose={onClose} />;
    });
  };

  return (
    <div className="flex gap-2">
      <Menu>
        <MenuButton
          className="flex items-center gap-2 shadow-xs rounded-md h-8 px-3 font-semibold shadow-white/10
          focus:outline-none text-xl"
        >
          <EllipsisHorizontalIcon className="w-6 h-6 text-gray-700" />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom start"
          className="border border-gray-200 rounded-xl bg-white shadow-md [--anchor-gap:8px] [--anchor-padding:8px]"
        >
          <MenuItem>
            <button
              onClick={showUpdateTitle}
              className="flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100 "
            >
              <PencilIcon className="w-6 h-6" />
              {t('service.service-detail.menu-edit-button.title', 'Edit title')}
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
            </button>
          </MenuItem>
          <hr />
          {isSuperAdmin && (
            <MenuItem>
              <button
                onClick={onDeleteMenuProduct}
                className="flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100"
              >
                <ArchiveBoxXMarkIcon className="h-6 w-6 text-red-600"></ArchiveBoxXMarkIcon>
                <span className=" text-red-600">
                  {t('service.service-detail.menu-edit-button.delete-product', 'Delete all products')}
                </span>
                <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
              </button>
            </MenuItem>
          )}
          <MenuItem>
            <button
              onClick={onDeleteMenu}
              className="flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100"
            >
              <TrashIcon className="h-6 w-6 text-red-600"></TrashIcon>
              <span className=" text-red-600">{t('service.service-detail.menu-edit-button.delete', 'Delete')}</span>
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
}
