import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const UPDATE_SERVICE_PRODUCT = graphql(`
    mutation UpdateAppointmentMenuProduct($id: ID!, $input: UpdateAppointmentMenuProductInput!) {
        updateAppointmentMenuProduct(id: $id, input: $input) {
            id
        }
    }
`);

export default function useUpdateServiceProduct() {
  return useMutation(UPDATE_SERVICE_PRODUCT, {});
}