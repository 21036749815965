import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { NailAppointmentConfirmationStatus, NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment, ReactElement } from 'react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { AppointmentTableListConfirmationStatus } from '@client/page/management/appointment/list/component/appointment-table-list-confirmation-status.tsx';
import classNames from 'classnames';

export default function NailAppointmentTableList(props: {
  loading?: boolean;
  error?: string;
  items: NailAppointmentItemFragment[];
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: (id: string) => void;
}) {
  const { items, actions } = props;
  const { t } = useTranslate();
  const company = useFocusedCompany();

  if (props.loading == false) {
    if (!props.error && items.length === 0) {
      return <div className="px-6 py-4 border-t">{t('appointment.no-appointment')}</div>;
    }
  }

  // Group by date
  const timezone = company.settings.timezone;
  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, appoinments]) => {
    const sortedAppointments = Array.from(appoinments);
    sortedAppointments.sort((a, b) => {
      return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      appointments: sortedAppointments,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    return moment(a.appointments[0].startTime).isAfter(moment(b.appointments[0].startTime)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        error={props.error}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-xl text-indigo-600">{item.date}</span>
              </div>
            </TableSection>

            {item.appointments.map((appointment) => (
              <AppointmentRow
                key={appointment.id}
                item={appointment}
                actions={actions}
                onClick={() => props.onClick?.(appointment.id)}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        <TableHeaderColumn className="w-14">{t('common.table-header-column.status', 'Status')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.time', 'Time')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.guest', 'Guest')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('common.table-header-column.service', 'Service')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden xl:table-cell">
          {t('common.table-header-column.message', 'Message')}
        </TableHeaderColumn>
      </Table>
    </>
  );
}

function AppointmentRow(props: {
  item: NailAppointmentItemFragment;
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: () => void;
}) {
  const { item, onClick, actions } = props;
  const company = useFocusedCompany();
  const timezone = company.settings.timezone;

  // const teamName = getFragmentData(PickedNailAppointmentTeamFragment, item.teams)[0]?.teamName;
  // const abbreviatedTeamName = _.join(
  //   _.map(_.split(teamName, ' '), (word) => word[0]),
  //   ''
  // ).toUpperCase();
  //
  // const employeeName = getFragmentData(PickedNailAppointmentEmployeeFragment, item.employees)[0]?.user.username ?? '';
  const serviceName = item.products[0] ? item.products[0].pickedProduct.title : '';

  return (
    <>
      <TableRow key={item.id} className="cursor-pointer overflow-hidden" onClick={onClick}>
        <TableRowColumn>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <AppointmentTableListConfirmationStatus appointmentId={item.id} status={item.confirmationStatus} />
          </div>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="font-bold">
          {nailAppointmentTimeFormat(item.startTime ?? '', timezone)}
        </TableRowColumn>

        {/* Name */}
        <TableRowColumn className="max-w-[80px] sm:max-w-32">
          <span>{item.customerVariant.name}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        {/* Service */}
        <TableRowColumn className="hidden sm:table-cell max-w-12 xl:max-w-32 truncate">
          <span className="font-semibold truncate  ">{serviceName}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        <TableRowColumn className="hidden xl:table-cell max-w-0.5 truncate overflow-hidden break-all">
          {item.message}
        </TableRowColumn>

        <TableRowColumn className="space-x-4">
          <div className="flex items-center justify-end ">
            <div className="max-sm:hidden">{actions?.({ id: item.id })}</div>
            <ChatBubbleOvalLeftEllipsisIcon
              className={classNames('fill-yellow-500 w-4 h-4 xl:hidden', {
                hidden: item.message?.length === 0,
              })}
            />
            <ChevronRightIcon className="w-7 fill-gray-400" />
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export function NailAppointmentIcon(props: { status: NailAppointmentConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case NailAppointmentConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case NailAppointmentConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case NailAppointmentConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}

function nailAppointmentTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
