import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const GET_SERVICE_DETAIL = graphql(`
  query GetServiceDetail($id: ID!) {
    serviceMenu(id: $id) {
      id
      companyId
      title
      menuSource
      sections {
        ...MenuSectionItem
      }
      menuProducts {
        ...ServiceItem
      }
    }
  }
`);

export function useGetService(menuId: string) {
  return useQuery(GET_SERVICE_DETAIL, {
    variables: {
      id: menuId,
    },
  });
}
