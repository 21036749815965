import { PickupDiscountType ,DeliveryDiscountType} from '@client/graphql/types/graphql.ts';
import { currencyUnit, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';

export function PickupDiscountTypeToRaw(type: PickupDiscountType, value: string, currency: string): number {
  switch (type) {
    case PickupDiscountType.None:
      return 0;
    case PickupDiscountType.Percentage:
      return parseInt(value);
    case PickupDiscountType.Amount:
      return normalizeCurrency(value, currency);
  }
}

export function PickupDiscountValueToDisplay(type: PickupDiscountType, value: number, currency: string): string {
  switch (type) {
    case PickupDiscountType.None:
      return '0';
    case PickupDiscountType.Percentage:
      return value.toString();
    case PickupDiscountType.Amount:
      return (value / currencyUnit(currency)).toString();
  }
}

export function DeliveryDiscountTypeToRaw(type: DeliveryDiscountType, value: string, currency: string): number {
  switch (type) {
    case DeliveryDiscountType.None:
      return 0;
    case DeliveryDiscountType.Percentage:
      return parseInt(value);
    case DeliveryDiscountType.Amount:
      return normalizeCurrency(value, currency);
  }
}

export function DeliveryDiscountValueToDisplay(type: DeliveryDiscountType, value: number, currency: string): string {
  switch (type) {
    case DeliveryDiscountType.None:
      return '0';
    case DeliveryDiscountType.Percentage:
      return value.toString();
    case DeliveryDiscountType.Amount:
      return (value / currencyUnit(currency)).toString();
  }
}
