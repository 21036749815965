import { RouteObject } from 'react-router-dom';
import HolidayList from '@client/page/management/holiday/holiday-list/holiday-list.tsx';

export const HolidayRouter: RouteObject = {
  path: 'holiday',
  children: [
    {
      path: '',
      element: <HolidayList />,
    },
  ],
};
