import { Link, useNavigate } from 'react-router-dom';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import Loading from '@packages/ui/loading.tsx';
import { getFragmentData } from '@client/graphql/types';
import { TeamFragment } from '@packages/network-graphql/common/team-fragment.ts';
import useGetBranchTeam from '@client/page/management/appointment/service/service-product/common-component/logic/get-branch-team.ts';

export default function TeamList() {
  const navigate = useNavigate();
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { loading, error, data, refetch } = useGetBranchTeam(branchId);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <>Something went wrong while fetching data returned.</>;
  }

  return (
    <ContentLayout>
      <Helmet>
        <title>Team list</title>
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={'Team list'}>
            <PrimaryButton
              onClick={() => {
                navigate('./create');
              }}
            >
              Create
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                refetch().catch((e) => void e);
              }}
            >
              <ArrowPathIcon className="w-4" />
            </SecondaryButton>
          </CardHeader>
          <CardTableContent>
            <Table
              loading={loading}
              data={getFragmentData(TeamFragment, data?.teams) ?? []}
              build={(team) => {
                return (
                  <TableRow key={team.id}>
                    <TableRowColumn>
                      <Link to={team.id}>{team.name}</Link>
                    </TableRowColumn>
                  </TableRow>
                );
              }}
            >
              <TableHeaderColumn>Name</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
