import { GraphQLFormattedError } from 'graphql';

export class ErrorBase<T extends string> extends Error {
  name: T;
  message: string;
  cause: string;

  constructor(name: T, message: string, cause: string) {
    super(message);
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}

export type ErrorDomain = 'Validation' | 'Unknown';

export interface ValidationErrorData {
  field: string;
  reason: string;
  children: ValidationErrorData[];
}

export interface ApplicationError {
  domain: ErrorDomain;
  code: number;
  message: string;
  data: unknown;
}

interface GraphQLExtension {
  errors: ApplicationError[];
}

export function formatGraphQlError(errors: readonly GraphQLFormattedError[] | undefined): ApplicationError[] | null {
  if (!errors) {
    return null;
  }

  return errors
    .map((e) => (e.extensions as unknown as GraphQLExtension).errors)
    .reduce((acc, val) => acc.concat(val), []);
}

export function validationErrors(errors: (ApplicationError | undefined)[] | null | undefined): Record<string, string> {
  return (
    errors
      ?.filter((e): e is ApplicationError => e !== undefined)
      .filter((e) => e.domain === 'Validation')
      .map((e) => {
        return e.data as ValidationErrorData;
      })
      .reduce((acc, cur) => {
        return { ...acc, [cur.field]: cur.reason };
      }, {}) ?? {}
  );
}

export function validationNestedErrors(
  errors: (ApplicationError | undefined)[] | null | undefined
): Record<string, string> {
  return (
    errors
      ?.filter((e): e is ApplicationError => e !== undefined)
      .filter((e) => e.domain === 'Validation')
      .map((e) => {
        return e.data as ValidationErrorData;
      })
      .reduce((acc, cur) => {
        if (cur.children.length != 0) {
          let result: Record<string, string> = {};
          cur.children.forEach((item) => {
            result = { ...result, [item.field]: item.reason };
          });
          return { ...acc, ...result };
        }
        return { ...acc, [cur.field]: cur.reason };
      }, {}) ?? {}
  );
}
