import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useAppointmentBranchConfiguration from '@client/page/management/appointment/configuration/logic/use-appointment-branch-configuration.ts';
import AppointmentBranchConfigurationSetup from '@client/page/management/appointment/configuration/component/appointment-branch-configuration-setup.tsx';
import AppointmentBranchConfigurationGeneral from '@client/page/management/appointment/configuration/component/appointment-branch-configuration-general.tsx';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentConfigurationFragment } from '@client/graphql/nail-appointment-configuration-fragment.ts';
import { useLocation } from 'react-router-dom';
import AppointmentBranchConfigurationTime from '@client/page/management/appointment/configuration/component/apointment-branch-configuration-time.tsx';
import Loading from '@packages/ui/loading.tsx';

export default function AppointmentBranchConfiguration() {
  const branch = useFocusedBranchContext();
  const location = useLocation();
  const { data, loading, refetch } = useAppointmentBranchConfiguration(branch.branch?.id ?? '');

  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <AppointmentBranchConfigurationSetup />;
  }

  const configuration = getFragmentData(NailAppointmentConfigurationFragment, data.appointmentBranchConfiguration);

  const refetchData = async () => {
    await refetch();
  };
  return (
    <>
      {location.pathname === '/appointment/configuration/general' && (
        <AppointmentBranchConfigurationGeneral configuration={configuration} refetchData={refetchData} />
      )}

      {location.pathname === '/appointment/configuration/opening-time' && <AppointmentBranchConfigurationTime />}
    </>
  );
}
