import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
    mutation OnlineOrderPrintToKitchen($id: ID!) {
        onlineOrderPrintToKitchen(id: $id)
    }
`);

export default function usePrintToKitchen() {
  return useMutation(MUTATION);
}
