import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const UPDATE_SERVICE_MENU_SECTION = graphql(`
  mutation updateServiceMenuSection(
    $id: ID!
    $input: UpdateServiceMenuSection!
    $timeRuleInput: UpdateMenuSectionTimeRule!
  ) {
    updateServiceMenuSection(id: $id, input: $input, timeRuleInput: $timeRuleInput) {
      id
      name
    }
  }
`);

export function useUpdateServiceMenuSection() {
  return useMutation(UPDATE_SERVICE_MENU_SECTION, {});
}
