import { Navigate, useSearchParams } from 'react-router-dom';
import AppointmentCreate from '@client/page/management/appointment/create/appointment-create.tsx';
import TableReservationCreate from '@client/page/management/table-reservation/create/table-reservation-create.tsx';

export default function DashboardCreateRoute() {
  const [searchParams] = useSearchParams();

  if (searchParams.get('target') == 'appointment') {
    return <AppointmentCreate />;
  }

  if (searchParams.get('target') == 'reservation') {
    return <TableReservationCreate />;
  }

  let target = '/dashboard';
  if (searchParams.get('startTime') && searchParams.get('endTime')) {
    target = `/dashboard?startTime=${searchParams.get('startTime') ?? ''}&endTime=${searchParams.get('endTime') ?? ''}`;
  }

  return <Navigate to={target} />;
}
