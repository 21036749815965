import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { OnlineOrderDashboard } from '@client/page/management/online-order/dashboard/online-order-dashboard.tsx';
import { TableReservationSimpleFilter } from '@client/page/management/table-reservation/list/component/table-reservation-simple-filter.tsx';
import { useSearchParams } from 'react-router-dom';
import { TableReservationPaginationFooter } from '@client/page/management/table-reservation/list/component/table-reservation-pagination-footer.tsx';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

const OnlineOrderDashboardSinglePage = () => {
  const [params, setParams] = useSearchParams();
  const { settings } = useFocusedCompany();

  const startTime = dayjs(params.get('startTime') ?? dayjs())
    .tz(settings.timezone)
    .startOf('day');
  const endTime = dayjs(params.get('endTime') ?? dayjs())
    .tz(settings.timezone)
    .endOf('day');

  const setTime = useCallback(
    (start: Dayjs, end: Dayjs) => {
      params.set('startTime', start.toISOString());
      params.set('endTime', end.toISOString());
      setParams(params);
    },
    [params, setParams]
  );

  return (
    <ContentLayout>
      <FullPrimaryContent>
        <OnlineOrderDashboard
          startTime={startTime}
          endTime={endTime}
          header={
            <>
              <TableReservationSimpleFilter
                startTime={startTime}
                endTime={endTime}
                onChange={(data) => {
                  setTime(data[0], data[1]);
                }}
              />
            </>
          }
          footer={
            <TableReservationPaginationFooter
              startTime={startTime}
              endTime={endTime}
              onChange={(data) => {
                setTime(data[0], data[1]);
              }}
            />
          }
        />
      </FullPrimaryContent>
    </ContentLayout>
  );
};

export default OnlineOrderDashboardSinglePage;
