import dayjs, { Dayjs } from 'dayjs';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { DatePicker } from 'antd';
import { useTranslate } from '@tolgee/react';

const { RangePicker } = DatePicker;

export function TableReservationRangeFilter(props: {
  startTime: Dayjs;
  endTime: Dayjs;
  onChange: (dates: [Dayjs, Dayjs]) => void;
}) {
  const { t } = useTranslate();

  return (
    <>
      <RangePicker
        allowClear={false}
        value={[props.startTime, props.endTime]}
        onChange={(range) => {
          if (range?.[0] && range[1]) {
            props.onChange([range[0], range[1]]);
          }
        }}
      />

      <SecondaryButton
        onClick={() => {
          props.onChange([dayjs().startOf('month').startOf('day'), dayjs().endOf('month').endOf('day')]);
        }}
      >
        {t('common.button.clear-filter')}
      </SecondaryButton>
    </>
  );
}
