import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import moment from 'moment-timezone';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import HolidayUpdate from '@client/page/management/holiday/holiday-update/holiday-update.tsx';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

interface HolidayTableRowData {
  id: string;
  startTime: string;
  endTime: string;
  description: string;
}

export default function HolidayTable(props: { loading?: boolean; items: HolidayTableRowData[] | undefined }) {
  const { items } = props;
  const company = useFocusedCompany();
  const { t } = useTranslate();

  if (!items) {
    return <div>Empty data</div>;
  }

  return (
    <>
      <Table
        loading={props.loading}
        data={items}
        build={(item) => <TableGroupRow key={item.id} holiday={item} timezone={company.settings.timezone} />}
      >
        <TableHeaderColumn>{t('holiday.holiday-list.table.date', 'Date')}</TableHeaderColumn>
        <TableHeaderColumn>{t('holiday.holiday-list.table.time', 'Time')}</TableHeaderColumn>
        <TableHeaderColumn>{t('holiday.holiday-list.table.description', 'Reason')}</TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { holiday: HolidayTableRowData; timezone: string }) {
  const { holiday, timezone } = props;
  const timeProcessed = timeProcess(holiday.startTime, holiday.endTime, timezone);
  const dialopgManager = useModalManagerContext();

  const handleUpdate = () => {
    dialopgManager.showDialog((onClose) => <HolidayUpdate holidayId={holiday.id} onClose={onClose} />);
  };

  return (
    <>
      <TableRow key={holiday.id} onClick={handleUpdate} className="hover:bg-gray-100">
        <TableRowColumn>
          <p className="w-24 text-wrap px-1 py-4 sm:w-full">
            <span>{timeProcessed.dateString.split('-')[0]}</span>
            <span className="hidden sm:inline-block">{timeProcessed.dateString.includes('-') && '-'}</span>
            <span>{timeProcessed.dateString.split('-')[1]}</span>
          </p>
        </TableRowColumn>
        <TableRowColumn>
          <p className="w-full">{timeProcessed.hourString}</p>
        </TableRowColumn>
        <TableRowColumn>
          <p className="w-full max-w-72 truncate">{holiday.description}</p>
        </TableRowColumn>
        <TableRowColumn>
          <div className="flex items-center justify-end ">
            <ChevronRightIcon className="w-7 fill-gray-400" />
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

function timeProcess(start: string, end: string, timezone: string) {
  const startTime = moment(start).tz(timezone).format('YYYY-MM-DDTHH:mm');
  const endTime = moment(end).tz(timezone).format('YYYY-MM-DDTHH:mm');

  const startDate = moment(startTime.split('T')[0]);
  const endDate = moment(endTime.split('T')[0]);

  const startHour = moment()
    .set('hour', parseInt(startTime.split('T')[1].slice(0, 2)))
    .set('minute', parseInt(startTime.split('T')[1].slice(3)));
  const endHour = moment()
    .set('hour', parseInt(endTime.split('T')[1].slice(0, 2)))
    .set('minute', parseInt(endTime.split('T')[1].slice(3)));

  const dateString =
    startTime.split('T')[0] == endTime.split('T')[0]
      ? startDate.format('MMM DD, YYYY')
      : `${startDate.format('MMM DD, YYYY')}  -  ${endDate.format('MMM DD, YYYY')}`;

  let hourString = `${startHour.format('HH:mm')} - ${endHour.format('HH:mm')}`;
  if (startHour.format('HH:mm') == '00:00' && endHour.format('HH:mm') == '23:59') hourString = 'All day';

  return {
    dateString,
    hourString,
  };
}
