import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation updateAppointmentConfirmationStatus($id: ID!, $status: NailAppointmentConfirmationStatus!) {
    updateAppointmentConfirmationStatus(id: $id, status: $status) {
      id
    }
  }
`);

export default function useUpdateAppointmentConfirmationStatus() {
  return useMutation(MUTATION, {
    refetchQueries: ['NailAppointments'],
  });
}
