import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import { AppointmentPaginationFooter } from '@client/page/management/appointment/list/component/appointment-paginition-footer.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import AppointmentList from '@client/page/management/appointment/list/appointment-list.tsx';
import { AppointmentSimpleFilter } from '@client/page/management/appointment/list/component/appointment-simple-filter.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useTranslate } from '@tolgee/react';

export default function AppointmentSinglePage() {
  const [params, setParams] = useSearchParams();
  const { settings } = useFocusedCompany();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const startTime = dayjs(params.get('startTime') ?? dayjs())
    .tz(settings.timezone)
    .startOf('day');
  const endTime = dayjs(params.get('endTime') ?? dayjs())
    .tz(settings.timezone)
    .endOf('day');

  // Function
  const setTime = useCallback(
    (start: Dayjs, end: Dayjs) => {
      params.set('startTime', start.toISOString());
      params.set('endTime', end.toISOString());
      setParams(params);
    },
    [params, setParams]
  );
  return (
    <ContentLayout>
      <FullPrimaryContent>
        <AppointmentList
          startTime={startTime}
          endTime={endTime}
          header={
            <>
              <AppointmentSimpleFilter
                startTime={startTime}
                endTime={endTime}
                onChange={(data) => {
                  setTime(data[0], data[1]);
                }}
              />
              <PrimaryButton
                onClick={() => {
                  navigate('create');
                }}
              >
                {t('common.create', 'Create')}
              </PrimaryButton>
            </>
          }
          footer={
            <AppointmentPaginationFooter
              startTime={startTime}
              endTime={endTime}
              onChange={(data) => {
                setTime(data[0], data[1]);
              }}
            />
          }
        />
      </FullPrimaryContent>
    </ContentLayout>
  );
}
