import EpsonServiceDirectPrintSettingPage
  from '@client/page/management/printer/epson-server-direct-print/epson-service-direct-print-setting.page.tsx';

export const PrinterRouter = {
  path: 'printer/setting',
  children: [
    {
      path: 'epson-server-direct-print',
      element: <EpsonServiceDirectPrintSettingPage />,
    },
  ],
};
