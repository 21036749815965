import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const CREATE_MANY_OPENING_TIME_MUTATION = graphql(`
    mutation CreateManyOpeningTime($input: CreateManyOpeningTimeData!) {
        createManyOpeningTime(input: $input) {
            id
        }
    }
`);

export function useCreateManyOpeningTime() {
  return useMutation(CREATE_MANY_OPENING_TIME_MUTATION, {
    refetchQueries: ['OpeningTimes'],
  });
}
