import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

export default function AppointmentStorefrontSetting(props: { branchID: string }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const copyLinkHandle = () => {
    void navigator.clipboard
      .writeText(`${import.meta.env.VITE_STOREFRONT_HOST as string}/appointment/create?&branchID=${props.branchID}`)
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: 'Link is copied',
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: 'Something went wrong',
        });
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={'Storefront setting'} />

        <CardContent>
          <FormLayout>
            <FormItem title={'Appointment link'}>
              <p
                className={'text-gray-400 text-sm mb-4 mt-3'}
              >{`${import.meta.env.VITE_STOREFRONT_HOST as string}/appointment/create?&branchID=${props.branchID}`}</p>
              <SecondaryButton
                onClick={() => {
                  copyLinkHandle();
                }}
              >
                {'Copy link'}
              </SecondaryButton>
            </FormItem>
          </FormLayout>
        </CardContent>
      </Card>
    </>
  );
}
