import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query Teams($branchId: ID!) {
    teams(branchId: $branchId) {
      ...TeamItem
    }
  }
`);

export default function useGetBranchTeam(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchId,
    },
  });
}
