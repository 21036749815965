import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
    query CustomerHistory($id: ID!) {
        customerAppointmentHistory(id: $id) {
            appointmentId
            detail
            time
        }
    }
`);

export default function useCustomerHistory(id: string) {
  return useQuery(QUERY, {
    variables: {
      id,
    },
  });
}
