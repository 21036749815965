import { useApiKeyList } from '@client/page/api-key/internal/list/logic/use-api-key-list.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CreateApiKeyButton from '@client/page/api-key/internal/list/component/create-api-key-button.tsx';

const ApiKeyList = () => {
  const { data, loading, error } = useApiKeyList();

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title="Internal API Keys">
            <CreateApiKeyButton />
          </CardHeader>

          <CardTableContent>
            <Table
              loading={loading}
              error={error?.message}
              data={data?.internalAPIKeys ?? []}
              build={(item) => {
                return (
                  <TableRow key={item.id}>
                    <TableHeaderColumn>{item.description}</TableHeaderColumn>
                    <TableHeaderColumn alignment={TableCellAlignment.Right}>{item.createdAt}</TableHeaderColumn>
                  </TableRow>
                );
              }}
            >
              <TableHeaderColumn>Description</TableHeaderColumn>
              <TableHeaderColumn alignment={TableCellAlignment.Right}>Created</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default ApiKeyList;
