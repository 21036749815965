import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation createTeam($branchId: ID!, $input: CreateTeamInput!, $memberTeam: [TeamMemberCreateInput!]!) {
    createTeam(branchId: $branchId, input: $input, memberTeam: $memberTeam) {
      id
      name
      description
    }
  }
`);

export default function useCreateTeam() {
  return useMutation(MUTATION, {});
}
