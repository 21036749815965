import { useMenuList } from '@client/page/management/menu/menu-list/logic/use-menu-list.ts';
import { Link, useNavigate } from 'react-router-dom';
import { MenuSource } from '@client/graphql/types/graphql.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { useTranslate } from '@tolgee/react';

export default function ServiceList() {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const { loading, error, data, refetch } = useMenuList(MenuSource.NailAppointment);

  return (
    <ContentLayout>
      <Helmet>{t('service.service-list.helmet.title', 'Service menu')}</Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('service.service-list.header', 'Service list')}>
            <PrimaryButton
              onClick={() => {
                navigate('./create');
              }}
            >
              {t('service.service-list.button.create', 'Create')}
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                refetch().catch((e) => void e);
              }}
            >
              <ArrowPathIcon className="w-4" />
            </SecondaryButton>
          </CardHeader>
          <CardTableContent>
            <Table
              loading={loading}
              error={error?.message}
              data={data?.menus ?? []}
              build={(menu) => {
                return (
                  <TableRow key={menu.id}>
                    <TableRowColumn>
                      <Link to={menu.id}>{menu.title}</Link>
                    </TableRowColumn>
                  </TableRow>
                );
              }}
            >
              <TableHeaderColumn>{t('common.table-header-column.name', 'Name')}</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
