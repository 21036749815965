import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from 'ui/src/button/primary-button';
import FormLayout from 'ui/src/form/form-layout';
import FormItem from 'ui/src/form/form-item';
import TextInput from 'ui/src/form/input/text-input';
import { PlatformType } from 'core/src/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import SecondaryButton from 'ui/src/button/secondary-button';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import useCreateTableBooking from './logic/use-create-table-booking';

interface TableBookingCreateForm {
  platformType: PlatformType;
  seats: number;
  guestName: string;
  message: string;
  bookingDate: string;
  bookingTime: string;
  phone: string;
  email: string;
}

interface LocationState {
  startTime: string;
}

export default function TableReservationCreate() {
  const notificationCenter = useNotificationCenter();

  const { settings } = useFocusedCompany();
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;
  const startTime = dayjs(state.startTime).format('YYYY-MM-DD');
  const startHour = dayjs(state.startTime).format('HH:mm');

  const [create, { error }] = useCreateTableBooking();
  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<TableBookingCreateForm>({
    platformType: PlatformType.System,
    seats: NaN,
    guestName: '',
    message: '',
    bookingDate: startTime,
    bookingTime: startHour,
    phone: '',
    email: '',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);
  const { t } = useTranslate();

  const handleSubmit = () => {
    const combinedDateTime = dayjs(`${form.bookingDate}T${form.bookingTime}`)
      .tz(settings.timezone, true)
      .format('YYYY-MM-DDTHH:mm:ss');

    create({
      variables: {
        branchId: branchID,
        input: {
          platformType: form.platformType,
          seats: +form.seats,
          guestName: form.guestName,
          message: form.message,
          startTime: combinedDateTime,
          email: form.email,
          phone: form.phone,
        },
      },
    })
      .then(() => {
        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('reservation.create.alert.message.success'),
          status: 'success',
        });

        navigate(-1);
      })
      .catch((e: Error) => {
        notificationCenter.showAlert({
          title: t('alert.title.error'),
          message: t('reservation.create.alert.message.failed') + e.message,
          status: 'error',
        });
      });
  };

  return (
    <ContentLayout>
      <Helmet>
        <title>Table Booking Create</title>
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('reservation.create.title')} withBackButton={true}></CardHeader>
          <CardContent>
            <FormLayout>
              <FormItem className="max-w-md" title={t('common.form-input.guest-name')}>
                <TextInput
                  type="text"
                  name="guestName"
                  label="guestName"
                  autoComplete="off"
                  placeholder={t('common.form-input.guest-name')}
                  error={validationError.guestName}
                  value={form.guestName}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.message')}>
                <textarea
                  id="message"
                  name="message"
                  rows={3}
                  value={form.message}
                  onChange={(e) => {
                    manualHandle('message', e.target.value);
                  }}
                  className="block h-60 w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.number-of-guests')}>
                <TextInput
                  type="number"
                  name="seats"
                  label="seats"
                  autoComplete="off"
                  placeholder={t('common.form-input.number-of-guests')}
                  error={validationError.seats}
                  value={form.seats}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.booking-date')}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="Booking date"
                  id="bookingDate"
                  name="bookingDate"
                  value={form.bookingDate}
                  type="date"
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.booking-time')}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="Booking time"
                  id="bookingTime"
                  name="bookingTime"
                  value={form.bookingTime}
                  type="time"
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.phone')}>
                <TextInput
                  type="number"
                  name="phone"
                  label="phone"
                  autoComplete="off"
                  placeholder={t('common.form-input.phone')}
                  error={validationError.phone}
                  value={form.phone}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.form-input.email')}>
                <TextInput
                  type="email"
                  name="email"
                  label="email"
                  autoComplete="off"
                  placeholder={t('common.form-input.email')}
                  error={validationError.email}
                  value={form.email}
                  onChange={handle}
                />
              </FormItem>
            </FormLayout>
          </CardContent>
          <CardFooter>
            <PrimaryButton onClick={handleSubmit}>{t('common.button.create')}</PrimaryButton>
            <SecondaryButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('common.button.cancel')}
            </SecondaryButton>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
