import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query holiday($id: ID!) {
    holiday(id: $id) {
      id
      startTime
      endTime
      description
    }
  }
`);

export default function useHoliday(id: string) {
  return useQuery(QUERY, {
    variables: { id },
  });
}
