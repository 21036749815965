import useFormData from '@client/module/utils/use-form-data';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import PrimaryButton from 'ui/src/button/primary-button';
import SecondaryButton from 'ui/src/button/secondary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useCallback, useEffect, useState } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { Helmet } from 'react-helmet-async';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context.ts';
import DestroyButton from 'ui/src/button/destroy-button.tsx';
import { OpeningTimeServiceType } from '@client/graphql/types/graphql.ts';
import OpeningTimeSetup from '@client/page/management/opening-time/opening-time-setup/opening-time-setup.tsx';
import useTableReservationSetting from './logic/use-table-reservation-setting.ts';
import useUpdateTableReservationSetting from './logic/use-update-table-reservation-setting.ts';

interface TableBookingSetting {
  status: boolean;
  pause: boolean;
  autoConfirm: boolean;
  seatLimit: number;
  ignoreStatus: boolean;
  notificationMails: string;
  phone: string;
  reservationLeadMinutes: number;
  reservationTraitMinutes: number;
  hourBlocking: number;
  defaultBookingTimeDuration: number;
  reminderTime: number;
  flexDuration: number[];
  flexEnabled: boolean;
}

export default function TableReservationSettingTime() {
  const notificationCenter = useNotificationCenter();
  const dialogManager = useModalManagerContext();
  const dialog = useDialog();

  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => hasBeenChanged && currentLocation.pathname !== nextLocation.pathname,
    [hasBeenChanged]
  );

  const blocker = useBlocker(shouldBlock);

  const { t } = useTranslate();

  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';

  const { data, refetch } = useTableReservationSetting(branchId);
  const [update] = useUpdateTableReservationSetting();
  const { data: form, setData } = useFormData<TableBookingSetting>({
    status: false,
    pause: false,
    autoConfirm: false,
    ignoreStatus: false,
    notificationMails: '',
    phone: '',
    reservationLeadMinutes: 0,
    reservationTraitMinutes: 0,
    hourBlocking: 0,
    defaultBookingTimeDuration: 0,
    seatLimit: 0,
    reminderTime: 60,
    flexDuration: [],
    flexEnabled: false,
  });

  useEffect(() => {
    setData({
      status: data?.tableReservationConfiguration.status ?? false,
      pause: data?.tableReservationConfiguration.pause ?? false,
      autoConfirm: data?.tableReservationConfiguration.autoConfirm ?? false,
      ignoreStatus: data?.tableReservationConfiguration.ignoreStatus ?? false,
      phone: data?.tableReservationConfiguration.phone ?? '',
      notificationMails: data?.tableReservationConfiguration.notificationMails.join('\n') ?? '',
      reservationLeadMinutes: data?.tableReservationConfiguration.reservationLeadMinutes ?? 0,
      reservationTraitMinutes: data?.tableReservationConfiguration.reservationTraitMinutes ?? 0,
      hourBlocking: data?.tableReservationConfiguration.hourBlocking ?? 0,
      defaultBookingTimeDuration: data?.tableReservationConfiguration.defaultBookingTimeDuration ?? 0,
      seatLimit: data?.tableReservationConfiguration.seatLimit ?? 0,
      reminderTime: data?.tableReservationConfiguration.reminderTime ?? 60,
      flexDuration: data?.tableReservationConfiguration.flexDuration ?? [],
      flexEnabled: data?.tableReservationConfiguration.flexEnabled ?? false,
    });
  }, [data, setData]);

  const reset = useCallback(() => {
    setData({
      status: data?.tableReservationConfiguration.status ?? false,
      pause: data?.tableReservationConfiguration.pause ?? false,
      autoConfirm: data?.tableReservationConfiguration.autoConfirm ?? false,
      ignoreStatus: data?.tableReservationConfiguration.ignoreStatus ?? false,
      phone: data?.tableReservationConfiguration.phone ?? '',
      notificationMails: data?.tableReservationConfiguration.notificationMails.join('\n') ?? '',
      reservationLeadMinutes: data?.tableReservationConfiguration.reservationLeadMinutes ?? 0,
      reservationTraitMinutes: data?.tableReservationConfiguration.reservationTraitMinutes ?? 0,
      hourBlocking: data?.tableReservationConfiguration.hourBlocking ?? 0,
      defaultBookingTimeDuration: data?.tableReservationConfiguration.defaultBookingTimeDuration ?? 0,
      seatLimit: data?.tableReservationConfiguration.seatLimit ?? 0,
      reminderTime: data?.tableReservationConfiguration.reminderTime ?? 60,
      flexDuration: data?.tableReservationConfiguration.flexDuration ?? [],
      flexEnabled: data?.tableReservationConfiguration.flexEnabled ?? false,
    });

    blocker.reset?.();
  }, [data, setData, blocker]);

  // Detect change between form and data
  useEffect(() => {
    if (data) {
      if (
        form.status !== data.tableReservationConfiguration.status ||
        form.pause !== data.tableReservationConfiguration.pause ||
        form.autoConfirm !== data.tableReservationConfiguration.autoConfirm ||
        form.ignoreStatus !== data.tableReservationConfiguration.ignoreStatus ||
        form.phone !== data.tableReservationConfiguration.phone ||
        form.notificationMails !== data.tableReservationConfiguration.notificationMails.join('\n') ||
        form.reservationLeadMinutes !== data.tableReservationConfiguration.reservationLeadMinutes ||
        form.reservationTraitMinutes !== data.tableReservationConfiguration.reservationTraitMinutes ||
        form.hourBlocking !== data.tableReservationConfiguration.hourBlocking ||
        form.defaultBookingTimeDuration !== data.tableReservationConfiguration.defaultBookingTimeDuration ||
        form.seatLimit !== data.tableReservationConfiguration.seatLimit ||
        form.reminderTime !== data.tableReservationConfiguration.reminderTime ||
        form.flexDuration !== data.tableReservationConfiguration.flexDuration ||
        form.flexEnabled !== data.tableReservationConfiguration.flexEnabled
      ) {
        setHasBeenChanged(true);
      } else {
        setHasBeenChanged(false);
      }
    }
  }, [data, form]);

  const handleSubmit = () => {
    if (!data) {
      notificationCenter.showAlert({
        title: t('alert.title.success'),
        message: t('reservation.setting.alert.message.error'),
        status: 'error',
      });
      return;
    }
    dialog.confirmAlert({
      title: t('reservation.setting.alert.confirm.title'),
      message: t('reservation.setting.alert.confirm.message'),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        await update({
          variables: {
            id: data.tableReservationConfiguration.id,
            input: {
              status: form.status,
              pause: form.pause,
              autoConfirm: form.autoConfirm,
              ignoreStatus: form.ignoreStatus,
              phone: form.phone,
              notificationMails: form.notificationMails.split('\n'),
              reservationLeadMinutes: +form.reservationLeadMinutes,
              reservationTraitMinutes: +form.reservationTraitMinutes,
              hourBlocking: +form.hourBlocking,
              defaultBookingTimeDuration: +form.defaultBookingTimeDuration,
              seatLimit: +form.seatLimit,
              reminderTime: +form.reminderTime,
              flexDuration: form.flexDuration,
              flexEnabled: form.flexEnabled,
            },
          },
        });

        await refetch();
        blocker.reset?.();

        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('reservation.setting.alert.message.success'),
          status: 'success',
        });
      },
    });
  };

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={t('reservation.setting.update-dialog.title')} />

          <CardContent>
            <p className="text-lg text-gray-600">{t('reservation.setting.update-dialog.content')}</p>
          </CardContent>

          <CardFooter>
            <div className="w-full flex justify-between">
              <PrimaryButton onClick={handleSubmit}>{t('common.button.update')}</PrimaryButton>

              <div className="flex gap-2">
                <DestroyButton
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  {t('common.button.discard')}
                </DestroyButton>
                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common.button.cancel')}
                </SecondaryButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state === 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });

  return (
    <ContentLayout>
      <Helmet>
        <title> {t('reservation.setting.helmet')}</title>
        <meta name="description" content="Table Booking Setting" />
      </Helmet>
      <PrimaryContent>
        <OpeningTimeSetup
          title={t('common.text.time')}
          service={OpeningTimeServiceType.TableReservation}
        />
      </PrimaryContent>
    </ContentLayout>
  );
}
