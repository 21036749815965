import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const DELETE_EPSON_DIRECT_PRINT_QUERY = graphql(`
    mutation DeleteEpsonServerDirectPrint($id: ID!) {
        deleteEpsonServerDirectPrint(id: $id)
    }
`);

export function useDeleteEpsonServerDirectPrint() {
  return useMutation(DELETE_EPSON_DIRECT_PRINT_QUERY, {
    refetchQueries: ['GetEpsonServerDirectPrint'],
  });
}
