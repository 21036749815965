import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
    mutation QuickCreateCompany($input: AdminQuickSetupInput!) {
        adminQuickSetup(input: $input) {
            username
            password
            company {
                id
            }
        }
    }
`);

export default function useQuickCreateCompany() {
  return useMutation(MUTATION, {});
}
