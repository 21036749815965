import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import {
  useEpsonServerDirectPrints,
} from '@client/page/management/printer/epson-server-direct-print/logic/use-epson-service-direct-print.ts';
import Loading from '@packages/ui/loading.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import { Fragment } from 'react';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import EpsonServerDirectPrintCreatePage
  from '@client/page/management/printer/epson-server-direct-print-create/epson-server-direct-print-create.page.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import {
  useDeleteEpsonServerDirectPrint,
} from '@client/page/management/printer/epson-server-direct-print/logic/use-delete-epson-server-direct-print.ts';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';

const EpsonServiceDirectPrintSettingPage = () => {
  const { t } = useTranslate();

  const { branch } = useFocusedBranchContext();
  const { showDialog } = useModalManagerContext();
  const { destructAlert } = useDialog();

  const { data, error, loading, refetch } = useEpsonServerDirectPrints(branch?.id ?? '');
  const [deleteEpsonServerDirectPrint] = useDeleteEpsonServerDirectPrint();

  if (loading) {
    return (
      <Loading />
    );
  }

  if (error) {
    return (
      <AlertError title={'Error'} message={error.message} />
    );
  }

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={'Epson Service Direct Print Setting'}>
            <PrimaryButton onClick={() => {
              showDialog((onClose) => {
                return <EpsonServerDirectPrintCreatePage onClose={onClose} />;
              });
            }}>Create</PrimaryButton>
            <SecondaryButton onClick={() => {
              void refetch();
            }}>Refresh</SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <Table data={data?.epsonServerDirectPrints ?? []} build={(item) => {
              const epsonPrinterDevice = item.epsonPrinterDevice ?? [];

              return <Fragment key={item.id}>
                <TableSection colSpan={5}>
                  <div className="flex items-center">
                    <div className="flex-grow">
                      {item.name} - ({item.id})
                    </div>
                    <SecondaryButton onClick={() => {
                      destructAlert({
                        title: 'Delete',
                        message: 'Are you sure you want to delete this server?',
                        textButton: 'Delete',
                        onConfirm: async () => {
                          await deleteEpsonServerDirectPrint({ variables: { id: item.id } });
                        },
                      });
                    }}>Delete</SecondaryButton>
                  </div>
                </TableSection>
                {
                  epsonPrinterDevice.map((device) => {
                      return (
                        <TableRow key={device.printerDeviceId}>
                          <TableRowColumn>{device.printerDevice.name}</TableRowColumn>
                          <TableRowColumn alignment={TableCellAlignment.Center}>{device.deviceId}</TableRowColumn>
                          <TableRowColumn
                            alignment={TableCellAlignment.Center}>{device.abstatus ?? 'No status'}</TableRowColumn>
                          <TableRowColumn
                            alignment={TableCellAlignment.Center}>{device.latestPrintError ?? 'Ok'}</TableRowColumn>
                          <TableRowColumn
                            alignment={TableCellAlignment.Center}>{device.latestPrintStatus ?? 'Ok'}</TableRowColumn>
                        </TableRow>
                      );
                    },
                  )
                }
              </Fragment>;
            }}>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn alignment={TableCellAlignment.Center}>Device ID</TableHeaderColumn>
              <TableHeaderColumn alignment={TableCellAlignment.Center}>Status</TableHeaderColumn>
              <TableHeaderColumn alignment={TableCellAlignment.Center}>Error</TableHeaderColumn>
              <TableHeaderColumn alignment={TableCellAlignment.Center}>Code</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>

        <Card>
          <CardHeader title={t('printer.epson-service-direct-print-settings.title', 'Setting')}/>
          <CardContent>
            <div className='flex flex-col gap-4'>
            <div>
              <p className="mb-2 text-sm text-gray-500">Print URL</p>
              <p className="max-w-md line-clamp-2 ">
                https://print-station.inhouse.96digital.de/epson/direct-print
              </p>
            </div>

            <div>
              <p className="mb-2 text-sm text-gray-500">Notification URL</p>
              <p className="max-w-md line-clamp-2 ">
                https://print-station.inhouse.96digital.de/epson/notification
              </p>
            </div>
            </div>
          </CardContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default EpsonServiceDirectPrintSettingPage;
