import { useMutation} from '@apollo/client';
import { graphql } from '@client/graphql/types';

const DELETE_OPENING_TIME_MUTATION = graphql(`
  mutation deleteOpeningTime($id: ID!) {
    deleteOpeningTime(id: $id) {
      id
    }
  }
`);

export function useDeleteOpeningTime() {
  return useMutation(DELETE_OPENING_TIME_MUTATION,{
    refetchQueries: ['OpeningTimes'],
  });
}
