import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query AppointmentBranchConfiguration($branchId: ID!) {
    appointmentBranchConfiguration(branchId: $branchId) {
      ...NailAppointmentConfigurationItem
    }
  }
`);

export default function useAppointmentBranchConfiguration(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchId,
    },
  });
}
