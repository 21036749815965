import { graphql } from '@client/graphql/types';

export const TableReservationFragment = graphql(`
    fragment TableReservationItem on TableReservation {
        id
        platformType
        tableId
        guestName
        status
        email
        message
        startTime
        phone
        seats
        confirmationStatus
        timezone
        flexTime
        cancelReason {
            id
            tableReservationId
            reason
            note
        }
    }
`);
