import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const COMPANY_SETTINGS_QUERY = graphql(`
    query Company($id: ID!) {
        company(id: $id) {
            id
            branch {
                id
            }
            settings {
                id
                currency
                timezone
            }
            hubPage {
                companyId
                subDomain
                description
                banner
            }
        }
    }
`);

export function useCompanySettings(companyID: string) {
  return useQuery(COMPANY_SETTINGS_QUERY, {
    variables: {
      id: companyID,
    },
  });
}
