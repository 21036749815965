import useHolidayList from '@client/page/management/holiday/holiday-list/logic/use-holiday-list.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import HolidayTable from '@client/page/management/holiday/holiday-list/component/holiday-table.tsx';
import Loading from '@packages/ui/loading.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import HolidayCreate from '@client/page/management/holiday/holiday-create/holiday-create.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';

export default function HolidayList() {
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const { data, loading } = useHolidayList(branchId);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <>Holiday is empty</>;
  }

  const holidayList = data.holidays.map((holiday) => ({
    id: holiday.id,
    startTime: holiday.startTime,
    endTime: holiday.endTime,
    description: holiday.description,
  }));

  const handleCreate = () => {
    dialogManager.showDialog((onClose) => <HolidayCreate onClose={onClose} />);
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('holiday.holiday-list.header', 'Holidays')}>
            <PrimaryButton onClick={handleCreate}>Create</PrimaryButton>
          </CardHeader>

          <CardTableContent>
            <HolidayTable items={holidayList} />
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
