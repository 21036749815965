import { useForm } from 'react-hook-form';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { LanguageSelector } from '@packages/ui/language-selector.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { DaysOfWeek, Features } from '@packages/network-graphql/graphql/types/graphql.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { captureException } from '@sentry/react';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { ApolloError } from '@apollo/client';
import FooterBar from '@client/page/component/footer-bar.tsx';
import useQuickCreateCompany from '@client/page/company/logic/use-quick-create-company.ts';

interface FormValues {
  businessName: string;
  email: string;
  phone: string;
  currency: string;
  address: string;
  website: string;
  subDomain: string;
  additionalAddress: string;
  additionalEmail: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  openTime: string;
  closeTime: string;
  openTime2: string | null;
  closeTime2: string | null;
  openingDays: DaysOfWeek[];
  features: Features[];
}

const dayOrder = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};


export default function CompanyQuickCreateForm() {
  const [params] = useSearchParams();
  const language = params.get('language') ?? 'en';

  const { t } = useTranslate();
  const navigate = useNavigate();
  const daysOfWeekOptions = Object.values(DaysOfWeek).sort((a, b) => dayOrder[a] - dayOrder[b]);

  const [createForm] = useQuickCreateCompany();

  const form = useForm<FormValues>({
    defaultValues: {
      currency: 'EUR',
      country: 'Germany',
      openingDays: [
        DaysOfWeek.Tuesday,
        DaysOfWeek.Wednesday,
        DaysOfWeek.Thursday,
        DaysOfWeek.Friday,
        DaysOfWeek.Saturday,
        DaysOfWeek.Sunday,
      ],
      email: '',
      openTime: '08:00',
      closeTime: '18:00',
      openTime2: null,
      closeTime2: null,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, handleSubmit, setError, clearErrors, watch } = form;

  const onSubmit = async (data: FormValues) => {
    try {
      const result = await createForm({
        variables: {
          input: {
            businessName: data.businessName.trim(),
            businessEmail: data.email.trim(),
            businessPhone: data.phone.toString(),
            currency: data.currency,
            website: data.website,
            subDomain: data.subDomain,
            additionalAddress: data.additionalAddress,
            streetAddress: data.address,
            addressLocality: data.city,
            addressRegion: data.state,
            postalCode: data.zipcode,
            addressCountry: data.country,
            openingTimes: {
              daysOfWeek: data.openingDays,
              openTime: data.openTime,
              closeTime: data.closeTime,
              openTime2: data.openTime2,
              closeTime2: data.closeTime2,
            },
            features: data.features,
          },
        },
      });
      if (result.data?.adminQuickSetup) {
        const { username, password, company } = result.data.adminQuickSetup;
        navigate('account', { state: { username: username, password: password, companyId: company.id } });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true },
          );
        }
      }

      captureException(err);
    }
  };

  return (
    <div className="flex flex-col  w-full justify-center items-center">
      <ContentLayout className="sm:py-20 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 2xl:w-1/3 ">
        <Helmet>
          <title>Create Form</title>
          <meta name="description" content="Create Form" />
        </Helmet>

        <FullPrimaryContent>
          <Card>
            <CardHeader title={t('create-form.header', 'Create Business Form')}>
              <LanguageSelector customerDefaultLanguage={language} />
            </CardHeader>
            <CardContent>
              <form
                className="space-y-6"
                onSubmit={() => {
                  handleSubmit(onSubmit);
                }}
              >
                <div className="grid grid-cols-6 gap-2 sm:gap-5">
                  {/* Name */}
                  <div className="col-span-6">
                    <FormItem
                      className=""
                      title={t('create-form.form-input.business-name.title', 'Business Name')}
                      obligatory={true}
                    >
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('businessName', {
                          required: t(
                            'create-form.form-input.business-name.validation.required',
                            'Business name is required',
                          ),
                          minLength: {
                            value: 3,
                            message: t(
                              'create-form.form-input.business-name.validation.short',
                              'Business name is too short',
                            ),
                          },
                          pattern: {
                            value: /^\S.*\S$/, // Regex to ensure no leading or trailing spaces
                            message: t(
                              'create-form.form-input.business-name.validation.no-space',
                              'Business name cannot have leading or trailing spaces',
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.businessName && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.businessName.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/* Email */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem
                      title={t('create-form.form-input.business-email.title', 'Business Email')}
                      obligatory={true}
                    >
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="email"
                        {...register('email', {
                          required: t(
                            'create-form.form-input.business-email.validation.required',
                            'Business email is required',
                          ),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t(
                              'create-form.form-input.business-email.validation.invalid',
                              'Invalid email address, example:email@gmail.com',
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.email && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.email.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/* Phone */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem
                      title={t('create-form.form-input.business-phone.title', 'Business Phone')}
                      obligatory={true}
                    >
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('phone', {
                          required: t('create-form.form-input.business-phone.validation.required', 'Phone is required'),
                          pattern: {
                            value: /^\d+$/,
                            message: t(
                              'create-form.form-input.business-phone.validation.invalid',
                              'Phone can only be numbers',
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.phone && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.phone.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/*Your website*/}
                  <div className="col-span-6 ">
                    <FormItem title={t('create-form.form-input.website.title', 'Your website')}>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        placeholder="https://"
                        {...register('website')}
                      />
                      {form.formState.errors.website && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.website.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/*Your subdomain*/}
                  <div className="col-span-6 ">
                    <FormItem
                      title={t('create-form.form-input.subdomain.title', 'Your service page')}
                      obligatory={true}
                    >
                      <p className="text-xs text-gray-400 pb-3 ">{`https://${watch('subDomain')}.96digital.de`}</p>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        {...register('subDomain', {
                          required: t('company-setting.hubpage.subdomain.validate.required', 'subdomain is required'),
                          minLength: {
                            value: 1,
                            message: t(
                              'company-setting.hubpage.subdomain.validate.min-length',
                              'subdomain must be at least 1 characters long',
                            ),
                          },
                          maxLength: {
                            value: 20,
                            message: t(
                              'company-setting.hubpage.subdomain.validate.max-length',
                              'subdomain must be at most 20 characters long',
                            ),
                          },
                          pattern: {
                            value: /^[a-z0-9-]+$/,
                            message: t(
                              'company-setting.hubpage.subdomain.validate.lowercase',
                              'subdomain must be lowercase and no space',
                            ),
                          },
                          validate: (value) => {
                            if (value === 'inhouse') {
                              return t(
                                'company-setting.hubpage.subdomain.validate.special.inhouse',
                                'The subdomain you entered is a special case, please try again.',
                              );
                            }
                          },
                        })}
                        placeholder={'Example: shop123'}
                      ></input>
                      {form.formState.errors.subDomain && (
                        <div className="text-red-500 text-xs pt-1">{form.formState.errors.subDomain.message}</div>
                      )}
                    </FormItem>
                  </div>

                  {/* Currency */}
                  <div className="col-span-6">
                    <FormItem title={t('create-form.form-input.currency.title', 'Currency')} />
                    <div className="mt-1 flex space-x-4">
                      {['EUR', 'USD', 'VND'].map((curr) => (
                        <label key={curr} className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio text-indigo-600"
                            value={curr}
                            {...register('currency')}
                          />
                          <span className="ml-2">{curr}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Address */}
                  <div className="col-span-6">
                    <FormItem title={t('create-form.form-input.address.title', 'Street Address')} obligatory={true}>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('address', {
                          required: t('create-form.form-input.address.validation.required', 'Address is required'),
                        })}
                      />
                      {form.formState.errors.address && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.address.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/*Additional address*/}
                  <div className="col-span-6">
                    <FormItem title={t('create-form.form-input.additional-address.title', 'Additional Address')} />
                    <input
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                      type="text"
                      {...register('additionalAddress')}
                    />
                    {form.formState.errors.additionalAddress && (
                      <p className="text-red-500 text-xs pt-1">{form.formState.errors.additionalAddress.message}</p>
                    )}
                  </div>

                  {/* City */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem title={t('create-form.form-input.city.title', 'City')} obligatory={true}>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('city', {
                          required: t('create-form.form-input.city.validation.required', 'City is required'),
                        })}
                      />
                      {form.formState.errors.city && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.city.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/* State */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem title={t('create-form.form-input.state.title', 'State')}>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('state')}
                      />
                      {form.formState.errors.state && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.state.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/* Postal Code */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem title={t('create-form.form-input.postal-code.title', 'Postal Code')} obligatory={true}>
                      <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        type="text"
                        {...register('zipcode', {
                          required: t(
                            'create-form.form-input.postal-code.validation.required',
                            'Postal code is required',
                          ),
                          pattern: {
                            value: /^\d+$/,
                            message: t(
                              'create-form.form-input.postal-code.validation.invalid',
                              'Postal code can only be numbers',
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.zipcode && (
                        <p className="text-red-500 text-xs pt-1">{form.formState.errors.zipcode.message}</p>
                      )}
                    </FormItem>
                  </div>

                  {/* Country */}
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem title={t('create-form.form-input.country.title', 'Country')}>
                      <select
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        {...register('country')}
                      >
                        <option value="Germany">Germany</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="England">America</option>
                      </select>
                    </FormItem>
                  </div>

                  {/*  Opening time*/}
                  <div className="col-span-6 pt-2">
                    <FormItem
                      title={t('create-form.form-input.opening-time.title', 'Opening Days')}
                      obligatory={false}
                    />
                  </div>
                  {daysOfWeekOptions.map((day) => (
                    <div className="col-span-3 sm:col-span-2" key={day}>
                      <label key={day} className="mr-4">
                        <input
                          type="checkbox"
                          value={day}
                          {...register('openingDays')}
                          className="form-checkbox text-indigo-600 rounded"
                        />
                        <span className="text-sm">
                          {' '}
                          {t('create-form.form-input.opening-time.day-of-week.' + day, day)}
                        </span>
                      </label>
                    </div>
                  ))}

                  <div className="col-span-3 sm:col-span-2"></div>
                  <div className="col-span-3 sm:col-span-2"></div>

                  {/*  Time*/}
                  <div className="col-span-6 grid-cols-8 grid gap-2">
                    {/*Time 1*/}
                    <div className="col-span-4 grid-cols-6 grid gap-2">
                      <FormItem
                        className="col-span-6 sm:col-span-3"
                        title={t('create-form.form-input.open-time.title', 'Open Time')}
                      >
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                          type="time"
                          {...register('openTime', {
                            deps: ['closeTime'],
                            validate: (openTime, form) => {
                              if (openTime > form.closeTime) {
                                return t(
                                  'create-form.form-input.open-time.validation.invalid',
                                  'Open time must be before close time',
                                );
                              }
                            },
                          })}
                        />
                        {form.formState.errors.openTime && (
                          <p className="text-red-500 text-xs pt-1">{form.formState.errors.openTime.message}</p>
                        )}
                      </FormItem>

                      <FormItem
                        className="col-span-6 sm:col-span-3 "
                        title={t('create-form.form-input.close-time.title', 'Close Time')}
                      >
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                          type="time"
                          {...register('closeTime', {
                            deps: ['openTime'],
                            validate: (closeTime, form) => {
                              if (closeTime < form.openTime) {
                                return t(
                                  'create-form.form-input.close-time.validation.invalid',
                                  'Close time must be after open time',
                                );
                              }
                            },
                          })}
                        />
                        {form.formState.errors.closeTime && (
                          <p className="text-red-500 text-xs pt-1">{form.formState.errors.closeTime.message}</p>
                        )}
                      </FormItem>
                    </div>

                    {/*Time 2*/}
                    <div className="col-span-4 grid-cols-6 grid gap-2">
                      <FormItem
                        className="col-span-6 sm:col-span-3 "
                        title={t('create-form.form-input.open-time-2.title', 'Open Time 2')}
                      >
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                          type="time"
                          {...register('openTime2', {
                            deps: ['closeTime2'],
                            validate: (openTime2, form) => {
                              if (openTime2 && form.closeTime2 && openTime2 > form.closeTime2) {
                                return t(
                                  'create-form.form-input.open-time-2.validation.invalid',
                                  'Open time must be before close time',
                                );
                              }
                            },
                          })}
                        />
                        {form.formState.errors.openTime2 && (
                          <p className="text-red-500 text-xs pt-1">{form.formState.errors.openTime2.message}</p>
                        )}
                      </FormItem>

                      <FormItem
                        className="col-span-6 sm:col-span-3 "
                        title={t('create-form.form-input.close-time-2.title', 'Close Time 2')}
                      >
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                          type="time"
                          {...register('closeTime2', {
                            deps: ['openTime2'],
                            validate: (closeTime2, form) => {
                              if (closeTime2 && form.openTime2 && closeTime2 < form.openTime2) {
                                return t(
                                  'create-form.form-input.close-time-2.validation.invalid',
                                  'Close time must be after open time',
                                );
                              }
                            },
                          })}
                        />
                        {form.formState.errors.closeTime2 && (
                          <p className="text-red-500 text-xs pt-1">{form.formState.errors.closeTime2.message}</p>
                        )}
                      </FormItem>
                    </div>
                  </div>
                  {/*  Features */}
                  <FormItem
                    className="col-span-6"
                    title={t('create-form.form-input.features.title', 'Features')}
                    obligatory={false}
                  >
                    <div className="grid-cols-6 grid">
                      {Object.values(Features).map((feat) => (
                        <div className="sm:col-span-2 col-span-6" key={feat}>
                          <label key={feat} className="mr-4">
                            <input
                              type="checkbox"
                              value={feat}
                              {...register('features',
                                { required: t('create-form.form-input.features.validation.required', 'Features is required') },
                              )}
                              className="form-checkbox text-indigo-600 rounded"
                            />
                            <span className="text-sm">
                          {' '}
                              {t('create-form.form-input.features.' + feat, feat)}
                        </span>
                          </label>
                        </div>
                      ))}
                    </div>
                    {form.formState.errors.features && (
                      <p className="text-red-500 text-xs pt-1">{form.formState.errors.features.message}</p>
                    )}
                  </FormItem>
                </div>
              </form>
            </CardContent>
            <CardFooter>
              <PrimaryButton
                onClick={() => {
                  clearErrors();
                  handleSubmit(onSubmit)().catch(captureException);
                }}
              >
                {t('create-form.form-input.submit-button', 'Submit')}
              </PrimaryButton>
            </CardFooter>
          </Card>
        </FullPrimaryContent>
      </ContentLayout>
      <FooterBar />
    </div>
  );
}
