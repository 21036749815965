import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

export default function useCustomLocation() {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState('');
  const [currentPath, setCurrentPath] = useState('');
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
      return;
    }
    initialized.current = true;
    // Khong duoc them currentPathDependency vao day
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return previousPath;
}
