import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const BRANCH_QUERY = graphql(`
    query Branch($id: ID!) {
        branch(id: $id) {
            name
            website
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry

            latitude
            longitude
            contactPhone
        }
    }
`);

export function useGetBranch(branchId: string) {
  return useQuery(BRANCH_QUERY, {
    variables: { id: branchId },
  });
}
