import useTableReservationDetailQuery
  from '@client/page/management/table-reservation/detail/logic/use-table-reservation-detail-query.ts';
import { BlockerFunction, createSearchParams, useBlocker, useNavigate, useParams } from 'react-router-dom';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getFragmentData } from '@client/graphql/types';
import { TableReservationFragment } from '@client/graphql/table-reservation-fragment.ts';
import { TableReservationConfirmationStatus, TableReservationSourceType } from '@client/graphql/types/graphql.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import dayjs from 'dayjs';
import useTableReservationUpdate
  from '@client/page/management/table-reservation/detail/logic/use-table-reservation-update.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Loading from '@packages/ui/loading.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import startCase from 'lodash/startCase';
import {
  TableReservationIcon,
} from '@client/page/management/table-reservation/list/component/reservation-table-list.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import useUpdateConfirmTableReservation
  from '@client/page/management/table-reservation/operative/logic/use-update-confirm.ts';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { captureException } from '@sentry/react';
import { extractMessageError } from '@client/module/utils/extract-message-error.ts';
import GreenButton from '@packages/ui/button/green-button.tsx';
import { objectIncludeSome } from '@client/module/utils/object-include.ts';
import { CalendarDateRangeIcon } from '@heroicons/react/24/outline';
import { CustomLocationContext } from '@client/app/research/custom-location-provider.tsx';

interface TableBookingUpdateForm {
  platformType: TableReservationSourceType;
  seats: number;
  guestName: string;
  message: string;
  bookingDate: string;
  bookingTime: string;
  phone: string;
  email: string;
}

const TableReservationDetail = () => {
  const reservationID = useParams().id;
  const { t } = useTranslate();

  const notificationCenter = useNotificationCenter();

  const navigate = useNavigate();

  const { data, loading, error } = useTableReservationDetailQuery(reservationID);


  const [update] = useTableReservationUpdate();
  const [updateConfirmationStatus] = useUpdateConfirmTableReservation();
  const previousPath = useContext(CustomLocationContext) ?? '';

  const dialogManager = useModalManagerContext();
  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => hasBeenChanged && currentLocation.pathname !== nextLocation.pathname,
    [hasBeenChanged],
  );

  const blocker = useBlocker(shouldBlock);

  const reservation = getFragmentData(TableReservationFragment, data?.tableReservation);

  const {
    data: formData,
    setData,
    manualHandle,
    handle,
  } = useFormData<TableBookingUpdateForm>({
    platformType: TableReservationSourceType.System,
    seats: 0,
    guestName: '',
    message: '',
    bookingTime: '',
    bookingDate: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    if (reservation) {
      const reservationTime = dayjs(reservation.startTime).tz(reservation.timezone);

      setData({
        email: reservation.email,
        guestName: reservation.guestName,
        message: reservation.message,
        phone: reservation.phone,
        platformType: reservation.platformType,
        seats: reservation.seats,
        bookingDate: reservationTime.format('YYYY-MM-DD'),
        bookingTime: reservationTime.format('HH:mm'),
      });
    }
  }, [reservation, setData]);

  const isFormChange = useCallback(() => {
    if (!reservation) return false;

    const reservationTime = dayjs(reservation.startTime).tz(reservation.timezone);

    return (
      formData.bookingDate !== reservationTime.format('YYYY-MM-DD') ||
      formData.bookingTime !== reservationTime.format('HH:mm') ||
      formData.email !== reservation.email ||
      formData.guestName !== reservation.guestName ||
      formData.message !== reservation.message ||
      formData.phone !== reservation.phone ||
      formData.seats !== reservation.seats
    );
  }, [
    formData.bookingDate,
    formData.bookingTime,
    formData.email,
    formData.guestName,
    formData.message,
    formData.phone,
    formData.seats,
    reservation,
  ]);

  const updateReservation = async () => {
    if (!reservation) return;

    try {
      await update({
        variables: {
          id: reservation.id,
          input: {
            email: formData.email,
            guestName: formData.guestName,
            message: formData.message,
            phone: formData.phone,
            seats: formData.seats,
            startTime: dayjs(`${formData.bookingDate}T${formData.bookingTime}`).tz(reservation.timezone, true).format(),
          },
        },
      });

      setHasBeenChanged(false);
      blocker.reset?.();

      notificationCenter.showAlert({
        status: 'success',
        message: t('reservation.edit.alert.message.success'),
        title: t('reservation.edit.alert.title'),
      });
    } catch (error) {
      notificationCenter.showAlert({
        status: 'error',
        message: t('reservation.edit.alert.message.error') + extractMessageError(error),
        title: t('reservation.edit.alert.title'),
      });
    }
  };

  const reset = useCallback(() => {
    if (reservation) {
      setData({
        bookingDate: dayjs(reservation.startTime).format('YYYY-MM-DD'),
        bookingTime: reservation.startTime,
        email: reservation.email,
        guestName: reservation.guestName,
        message: reservation.message,
        phone: reservation.phone,
        platformType: reservation.platformType,
        seats: reservation.seats,
      });

      blocker.reset?.();
    }
  }, [blocker, reservation, setData]);

  // Detect change between form and data
  useEffect(() => {
    setHasBeenChanged(isFormChange());
  }, [isFormChange]);

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={'Content has been changed'} />

          <CardContent>
            <p className="text-lg text-gray-600">The content will be lost if you do not save.</p>
          </CardContent>

          <CardFooter>
            <div className="w-full">
              <div className="flex justify-end gap-2">
                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </SecondaryButton>
                <DestroyButton
                  onClick={() => {
                    reset();
                    onClose();
                    setHasBeenChanged(false);
                    navigate(-1);
                  }}
                >
                  Discard
                </DestroyButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state === 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });

  const onUpdateStatusHandler = async (status: TableReservationConfirmationStatus) => {
    if (!reservation) return;

    try {
      await updateConfirmationStatus({
        variables: {
          id: reservation.id,
          status: status,
        },
      });

      notificationCenter.showAlert({
        status: 'success',
        message: t('reservation.edit.alert.message.success'),
        title: t('reservation.edit.alert.title'),
      });

      navigate(-1);
    } catch (error) {
      notificationCenter.showAlert({
        status: 'error',
        message: t('reservation.edit.alert.message.error') + extractMessageError(error),
        title: t('reservation.edit.alert.title'),
      });

      navigate({
        pathname: '..',
        search: createSearchParams({
          startTime: reservation.startTime,
          endTime: reservation.startTime,
        }).toString(),
      });
    }
  };

  const cancelReasonString = reservation?.cancelReason?.reason.map(reason =>
    t('table-reservation.detail.cancel-reason.' + String(reason).toLowerCase(), String(reason).toLowerCase())).join(', ') ?? '';


  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ApplicationErrorView error={error} />;
  }

  if (!reservation) {
    return <AlertError title={'Not found'} message={'Reservation not found'} />;
  }

  const backTarget = previousPath.includes('history')
    ? `..?nearestDetailTime=${dayjs(reservation.startTime).toISOString()}`
    : -1;

  return (
    <ContentLayout>
      <Helmet>
        <title>Reservation Detail</title>
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('reservation.edit.title')} withBackButton={true} backTarget={backTarget} />
          <CardContent>
            <FormLayout>
              <FormItem title={t('common.form-input.status')}>
                <div className="flex space-x-1">
                  <TableReservationIcon status={reservation.confirmationStatus} />
                  <p>{t(startCase(reservation.confirmationStatus.toLowerCase()))}</p>
                </div>
              </FormItem>

              {/*Cancel reason*/}
              {reservation.cancelReason?.reason && (
                <FormItem title={t('table-reservation.detail.cancel-reason', 'Cancel Reason')}>
                  <p className="text-lg ml-0.5 md:max-w-md line-clamp-2 break-all text-ellipsis">{cancelReasonString}</p>
                </FormItem>
              )}
              {reservation.cancelReason?.note && (
                <FormItem title={t('table-reservation.detail.cancel-note', 'Cancel Note')}>
                  <p className="text-lg ml-0.5 md:max-w-md line-clamp-2 break-all text-ellipsis">{reservation.cancelReason.note}</p>
                </FormItem>
              )}
              {/* Name */}
              <FormItem className="max-w-md" title={t('common.form-input.name')}>
                <TextInput
                  type="text"
                  label="guestName"
                  value={formData.guestName}
                  name="guestName"
                  placeholder={t('common.placeholder.guest-name')}
                  onChange={handle}
                />
              </FormItem>

              {/*  Message */}
              <FormItem className="max-w-md" title={t('common.form-input.message')}>
                <textarea
                  id="message"
                  name="message"
                  rows={3}
                  value={formData.message}
                  onChange={(e) => {
                    manualHandle('message', e.target.value);
                  }}
                  className="block h-60 w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </FormItem>

              {/* Seat */}
              <FormItem className="max-w-md" title={t('common.form-input.number-of-guests')}>
                <TextInput
                  type="number"
                  label="seats"
                  value={formData.seats}
                  name="seats"
                  placeholder={t('common.placeholder.number-of-guests')}
                  onChange={(value) => {
                    manualHandle('seats', parseInt(value.target.value));
                  }}
                />
              </FormItem>

              <FormItem className="max-w-md" title={'Booking date'}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="Booking date"
                  id="bookingDate"
                  name="bookingDate"
                  value={formData.bookingDate}
                  type="date"
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('common.booking-time')}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="Booking time"
                  id="bookingTime"
                  name="bookingTime"
                  value={formData.bookingTime}
                  type="time"
                  onChange={handle}
                />
              </FormItem>

              {objectIncludeSome(reservation, ['flexTime']) && (
                <FormItem title={t('reservation.edit.form-input.flex-time.title')}>
                  {reservation.flexTime && (
                    <div className="flex flex-row gap-1 items-center">
                      <CalendarDateRangeIcon className="w-5 h-5 stroke-amber-700" />
                      <p>
                        {t({
                          key: 'reservation.edit.form-input.flex-time.description',
                          defaultValue: 'The user can move the reservation up to {minute} minutes later',
                          params: { minute: reservation.flexTime },
                        })}
                      </p>
                    </div>
                  )}
                </FormItem>
              )}

              {/* Email */}

              <FormItem className="max-w-md" title={t('common.form-input.email')}>
                <TextInput
                  type="text"
                  label="email"
                  value={formData.email}
                  name="email"
                  placeholder={t('common.placeholder.guest-email')}
                  onChange={handle}
                />
              </FormItem>

              {/*  Phone */}
              <FormItem className="max-w-md" title={t('common.form-input.phone')}>
                <TextInput
                  type="text"
                  label="phone"
                  value={formData.phone}
                  name="phone"
                  placeholder={t('common.placeholder.guest-phone')}
                  onChange={handle}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            {hasBeenChanged ? (
              <>
                <PrimaryButton
                  onClick={() => {
                    updateReservation().catch(captureException);
                  }}
                >
                  {t('common.button.save')}
                </PrimaryButton>

                <SecondaryButton
                  onClick={() => {
                    setHasBeenChanged(false);
                    reset();
                  }}
                >
                  {t('common.button.discard', 'Discard')}
                </SecondaryButton>
              </>
            ) : (
              <>
                <GreenButton
                  onClick={() => {
                    onUpdateStatusHandler(TableReservationConfirmationStatus.Confirmed).catch(captureException);
                  }}
                >
                  {t('common.button.approve')}
                </GreenButton>

                <DestroyButton
                  onClick={() => {
                    onUpdateStatusHandler(TableReservationConfirmationStatus.Cancelled).catch(captureException);
                  }}
                >
                  {t('common.button.reject')}
                </DestroyButton>
              </>
            )}
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default TableReservationDetail;
