import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const QUERY = graphql(`
  query ApiKeyListPage {
    internalAPIKeys {
      id
      key
      description
      createdAt
      updatedAt
    }
  }
`);

export function useApiKeyList() {
  return useQuery(QUERY, {});
}
