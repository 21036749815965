import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

export const GET_SERVICE_PRODUCT = graphql(`
  query appointmentMenuProduct($id: ID!) {
    appointmentMenuProduct(id: $id) {
      id
      title
      priceMode
      description
      images
      ingredients
      labels
      priceMode
      allergics
      section {
        id
        name
        description
      }
      configurations {
        id
        title
        type
        values {
          id
          name
          price
        }
      }

      appointmentMenuProductData {
        duration
        nailAppointmentMenuProductTeams {
          id
          teamId
          menuProductDataId
        }
      }

      menuId
      sectionId
    }
  }
`);

export function useGetServiceProduct(id: string) {
  return useQuery(GET_SERVICE_PRODUCT, { variables: { id } });
}
