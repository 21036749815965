import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query holidays($branchId: ID!) {
    holidays(branchId: $branchId) {
      id
      startTime
      endTime
      description
    }
  }
`);

export default function useHolidayList(branchId: string) {
  return useQuery(QUERY, {
    variables: { branchId },
  });
}
