import {
  NailAppointmentConfirmationStatus,
  TableReservationConfirmationStatus,
} from '@client/graphql/types/graphql.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { TableReservationIcon } from '@client/page/management/table-reservation/list/component/reservation-table-list.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import useUpdateAppointmentConfirmationStatus from '@client/page/management/appointment/detail/logic/use-update-appointment-confirmation-status.ts';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

export function AppointmentTableListConfirmationStatus(props: {
  appointmentId: string;
  status: NailAppointmentConfirmationStatus;
}) {
  const { t } = useTranslate();
  const notificationCenter = useNotificationCenter();
  const [update] = useUpdateAppointmentConfirmationStatus();

  const onUpdateStatusHandler = (status: NailAppointmentConfirmationStatus) => {
    if (status == props.status) {
      return;
    }

    update({
      variables: {
        id: props.appointmentId,
        status: status,
      },
    })
      .then(() => {
        notificationCenter.showAlert({
          status: 'success',
          message: 'Update success!',
          title: 'Update appointment',
        });
      })
      .catch((error: Error) => {
        notificationCenter.showAlert({
          status: 'error',
          message: 'Update failed! ' + error.message,
          title: 'Update appointment',
        });
      });
  };

  return (
    <Menu>
      <MenuButton
        className={
          'flex justify-center items-center gap-1 focus:outline-none rounded-md bg-white font-semibold' +
          ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2 py-1 text-sm'
        }
      >
        <AppointmentStatus status={props.status} />
      </MenuButton>

      <MenuItems
        anchor="bottom start"
        className={
          'focus:outline-none absolute min-w-28 mt-1 z-50 rounded-md border border-gray-300  p-1 text-sm/6 bg-white'
        }
      >
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(NailAppointmentConfirmationStatus.Confirmed);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Confirmed} />
            <p className="font-semibold">{t('common.confirmation.confirm')} </p>
          </span>
        </MenuItem>
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(NailAppointmentConfirmationStatus.Cancelled);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Cancelled} />
            <p className="font-semibold">{t('common.confirmation.cancel')}</p>
          </span>
        </MenuItem>
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(NailAppointmentConfirmationStatus.Waiting);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Waiting} />
            <p className="font-semibold">{t('common.confirmation.waiting')}</p>
          </span>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

export function AppointmentStatus(props: { status: NailAppointmentConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case NailAppointmentConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case NailAppointmentConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case NailAppointmentConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}
