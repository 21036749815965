import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import InternalApiKeyCreatePage from '@client/page/api-key/internal/create/internal-api-key-create-page.tsx';

const CreateApiKeyButton = () => {
  const { showDialog } = useModalManagerContext();

  return (
    <PrimaryButton
      onClick={() => {
        showDialog((onClose) => {
          return <InternalApiKeyCreatePage onClose={onClose} />;
        });
      }}
    >
      Create API Key
    </PrimaryButton>
  );
};

export default CreateApiKeyButton;
