import { graphql } from '../graphql/types';

export const TeamFragment = graphql(`
  fragment TeamItem on Team {
    id
    name
    description
    teamMember {
      id
      userId
      user {
        username
        firstName
        lastName
      }
    }
  }
`);
