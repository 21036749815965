import moment from 'moment-timezone';

export default function validateTime(startTime: string, endTime: string) {
  const errors: Record<string, string> = {};
  const startDate = moment(startTime.split('T')[0]);
  const endDate = moment(endTime.split('T')[0]);

  const startHour = moment()
    .set('hour', parseInt(startTime.split('T')[1].slice(0, 2)))
    .set('minute', parseInt(startTime.split('T')[1].slice(3)));
  const endHour = moment()
    .set('hour', parseInt(endTime.split('T')[1].slice(0, 2)))
    .set('minute', parseInt(endTime.split('T')[1].slice(3)));

  if (startDate.isAfter(endDate)) {
    errors.date = 'holiday.form-input.date';
  }

  if (startDate.format('YYYY-MM-DD') == endDate.format('YYYY-MM-DD') || startDate.isBefore(endDate)) {
    if (startHour.isAfter(endHour)) {
      errors.hour = 'holiday.form-input.hour';
    }
  }

  return errors;
}
