import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation deleteAllMenuProduct($menuId: ID!) {
    deleteAllMenuProducts(menuId: $menuId)
  }
`);

export default function useDeleteAllMenuProduct() {
  return useMutation(MUTATION, {});
}
