import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation OnlineOrderDecline($id: ID!) {
    onlineOrderOwnerDecline(id: $id)
  }
`);

export default function useDeclineOnlineOrder() {
  return useMutation(MUTATION, {
    refetchQueries: ['OnlineOrderDetail', 'OnlineOrderDashboard', 'OnlineOrderHistory'],
  });
}
