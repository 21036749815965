import useFormData from '@client/module/utils/use-form-data';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import ToggleInput from 'ui/src/form/input/toggle-input';
import PrimaryButton from 'ui/src/button/primary-button';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import {
  OnlineOrderBranchSettingItemFragment,
} from '@client/graphql/types/graphql';
import ApplicationErrorView from '@client/module/error/application-error-view';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context';
import SecondaryButton from 'ui/src/button/secondary-button';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import AlertError from 'ui/src/alert/alert-error';
import { useCallback, useEffect, useState } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import DestroyButton from 'ui/src/button/destroy-button';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useTranslate } from '@tolgee/react';
import useUpdateOnlineOrderConfiguration from '../logic/use-update-online-order-branch-setting';

interface OnlineOrderBranchSettingForm {
  printToKitchen: boolean;
}

export default function OnlineOrderBranchPrinterSettingEdit(props: {
  onlineOrderBranchSettingData: OnlineOrderBranchSettingItemFragment;
}) {
  // Params
  const { onlineOrderBranchSettingData } = props;

  // Hooks
  const currency = useCompanyCurrency();
  const dialogManager = useModalManagerContext();
  const dialog = useDialog();
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return hasBeenChanged && currentLocation.pathname !== nextLocation.pathname;
    },
    [hasBeenChanged],
  );

  const blocker = useBlocker(shouldBlock);
  const {
    data: form,
    manualHandle,
    setData,
  } = useFormData<OnlineOrderBranchSettingForm>({
    printToKitchen: onlineOrderBranchSettingData.printToKitchen,
  });
  const [update, { loading: updateLoading, error: updateError }] = useUpdateOnlineOrderConfiguration();

  const reset = useCallback(() => {
    setData({
      printToKitchen: onlineOrderBranchSettingData.printToKitchen,
    });

    blocker.reset?.();
  }, [blocker, onlineOrderBranchSettingData.printToKitchen, setData]);

  //Detect change between form and data
  useEffect(() => {
    if (form.printToKitchen != onlineOrderBranchSettingData.printToKitchen) {
      setHasBeenChanged(true);
    } else {
      setHasBeenChanged(false);
    }
  }, [currency, form, onlineOrderBranchSettingData]);
  // Data
  const loading = updateLoading;
  // Callbacks
  const handleSubmit = () => {
    dialog.confirmAlert({
      title: t('online-order.setting-page.edit.dialog.title'),
      message: t('online-order.setting-page.edit.dialog.message'),
      textButton: t('online-order.setting-page.edit.dialog.text-button'),
      onConfirm: async () => {
        await update({
          variables: {
            branchID: onlineOrderBranchSettingData.branchID,
            input: {
              printToKitchen: form.printToKitchen,
            },
          },
        });

        blocker.reset?.();

        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.edit.alert.success.message'),
        });
      },
    });
  };

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={t('online-order.setting-page.edit.alert-changed.title')} />

          <CardContent>
            <p className="text-lg text-gray-600">{t('online-order.setting-page.edit.alert-changed.content')}</p>
          </CardContent>

          <CardFooter>
            <div className="w-full flex justify-between">
              <PrimaryButton
                disabled={loading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('common.button.update')}
              </PrimaryButton>
              <div className="flex gap-2">
                <DestroyButton
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  {t('common.button.discard')}
                </DestroyButton>

                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common.button.cancel')}
                </SecondaryButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state == 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });

  return (
    <>
      <ContentLayout>
        <PrimaryContent>
          <ApplicationErrorView error={updateError} />
          {onlineOrderBranchSettingData.deliveryTableData?.length == 0 && (
            <AlertError
              title={t('online-order.setting-page.edit.alert-error.title')}
              message={t('online-order.setting-page.edit.alert-error.message')}
            />
          )}
          <Card>
            <CardHeader title={t('online-order.setting-page.edit.general.title')} />

            <CardContent>
              <FormLayout>
                <FormItem title={t('online-order.setting-page.edit.printer.print-to-kitchen.title', 'Print to kitchen')}>
                  <p className="text-sm text-gray-500 italic my-4">
                    {t('online-order.setting-page.edit.printer.print-to-kitchen.description', 'When customer place an order, print to kitchen')}
                  </p>
                  <ToggleInput
                    value={form.printToKitchen}
                    onChange={(newVal) => {
                      manualHandle('printToKitchen', newVal);
                    }}
                  />
                </FormItem>
              </FormLayout>
            </CardContent>

            <CardFooter>
              <PrimaryButton disabled={loading} onClick={handleSubmit}>
                {t('common.button.update')}
              </PrimaryButton>
            </CardFooter>
          </Card>
        </PrimaryContent>
      </ContentLayout>
    </>
  )
    ;
}
