import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import ToggleInput from 'ui/src/form/input/toggle-input';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import PrimaryButton from 'ui/src/button/primary-button';
import CardHeader from 'ui/src/card/card-header';
import { useNavigate, useParams } from 'react-router-dom';
import { TimeRuleType, ContentType } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import { useEffect } from 'react';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error';
import FullDateView from '@packages/ui/date/full-date-view.tsx';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { client } from '@client/graphql/client.ts';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import { useTranslate } from "@tolgee/react";
import { Helmet } from 'react-helmet-async';
import { useTimeRuleForm } from '../logic/use-time-rule-form';
import { OverlayBannerPreview } from '../banner-preview/overlay-banner-preview';
import BannerDeleteButton from './component/banner-delete-button';
import useBanner from './logic/use-banner';
import useBannerUpdate from './logic/use-banner-update';

interface OverlayBannerForm {
  title: string;
  enabled: boolean;
  contentType: ContentType;
  image: string;
}

export default function OverlayBannerUpdate() {
  const navigate = useNavigate();
  const { settings } = useFocusedCompanyContext();
  const bannerId = useParams().bannerId ?? '';
  const [update, { loading, error: updateError }] = useBannerUpdate();
  const { data, error: loadingError } = useBanner(bannerId);
  const {
    data: timeRuleForm,
    manualHandle: manualUpdateTimeRuleForm,
    setTimeRuleType,
  } = useTimeRuleForm(data?.banner.timeRule);

  const {
    data: form,
    handle,
    manualHandle,
    setData,
  } = useFormData<OverlayBannerForm>({
    title: '',
    enabled: false,
    contentType: ContentType.Top,
    image: '',
  });

  const error = loadingError ?? updateError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  useEffect(() => {
    setData({
      title: data?.banner.title ?? ' ',
      enabled: data?.banner.enabled ?? true,
      contentType: ContentType.Overlay,
      image:
        (data?.banner.content.__typename == 'OverlayContent'
          ? data.banner.content.image
          : '') ?? '',
    });
  }, [data, setData]);

  const onSubmit = async () => {
    /* eslint-disable no-console */
    console.log(timeRuleForm);
    await update({
      variables: {
        bannerId: bannerId,
        input: {
          title: form.title,
          enabled: form.enabled,
        },
        timeRuleInput: {
          type: timeRuleForm.timeRuleType,
          start: timeRuleForm.start,
          end: timeRuleForm.end,
        },
        contentInput: {
          type: form.contentType,
          image: form.image,
        },
      },
    })
      .then(() => {
        navigate('../..');
      })
      .catch((e) => {
        /* eslint-disable no-console */
        console.log(e);
      });
  };
  const { t } = useTranslate();
  return (
    <div className="flex flex-col sm:flex-row">
      <Helmet>
        <title>Update overlay banner</title>
        <meta
          name="description"
          content="This page allows you to update an overlay banner"
        />
      </Helmet>

      <div className="flex flex-col sm:w-2/3 space-y-4">
        <Card>
          <CardHeader
            withBackButton={true}
            backTarget="../.."
            title={t('overlay-banner-update.header')}
          />

          <CardContent>
            <FormLayout>
              <FormItem
                title={t('overlay-banner-update.title')}
                className="max-w-md"
              >
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder="Title"
                  value={form.title}
                  error={validationError.title}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Logo'} className="max-w-md">
                <ImageInput
                  client={client}
                  images={[form.image]}
                  onChange={(newValue) => {
                    if (newValue.length == 0) {
                      manualHandle('image', '');
                    } else {
                      manualHandle('image', newValue[0]);
                    }
                  }}
                />
              </FormItem>

              <FormItem title={t('banner.time-rule-type')} className="max-w-md">
                <div className="flex flex-col gap-4">
                  <div className="">
                    <MenuSelectionInput
                      title=""
                      data={Object.values(TimeRuleType)}
                      value={timeRuleForm.timeRuleType}
                      onChange={(newValue) => {
                        setTimeRuleType(newValue);
                      }}
                      build={(item) => {
                        return {
                          id: item,
                          name: item,
                        };
                      }}
                      className="-my-2 w-full"
                    />
                  </div>
                  {timeRuleForm.timeRuleType == TimeRuleType.RangeTime && (
                    <div>
                      <FormItem
                        title={t('overlay-banner-update.timezone')}
                        className="pt-4"
                      >
                        <TextInput
                          type={'text'}
                          label={'timezone'}
                          value={settings.timezone}
                          name={'timezone'}
                          placeholder={'Timezone'}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onChange={() => {}}
                        />
                      </FormItem>

                      <FormItem title="Range" className="pt-4">
                        <div className="max-w-full flex gap-2">
                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="Start Date"
                            id="start"
                            name="start"
                            value={moment(timeRuleForm.start).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                            type="datetime-local"
                            onChange={(event) => {
                              const value = event.target.value;
                              manualUpdateTimeRuleForm(
                                'start',
                                moment(value)
                                  .tz(settings.timezone)
                                  .toISOString()
                              );
                            }}
                          />

                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="End Date"
                            id="end"
                            name="end"
                            value={moment(timeRuleForm.end).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                            type="datetime-local"
                            onChange={(event) => {
                              const value = event.target.value;
                              manualUpdateTimeRuleForm(
                                'end',
                                moment(value)
                                  .tz(settings.timezone)
                                  .toISOString()
                              );
                            }}
                          />
                        </div>

                        <div>
                          {timeRuleForm.start &&
                            timeRuleForm.end &&
                            timeRuleForm.start >= timeRuleForm.end && (
                              <p
                                className="mt-2 text-xs italic text-red-600"
                                id="email-error"
                              >
                                {
                                  'Choose startDate and endDate, startDate must be smaller than endDate'
                                }
                              </p>
                            )}
                        </div>
                      </FormItem>
                    </div>
                  )}

                  {timeRuleForm.timeRuleType == TimeRuleType.Always && (
                    <div></div>
                  )}
                </div>
              </FormItem>

              <FormItem title={t('common.active')} className="w-32">
                <ToggleInput
                  value={form.enabled}
                  onChange={(newValue) => {
                    manualHandle('enabled', newValue);
                  }}
                />
              </FormItem>

              <FormItem
                title={t('overlay-banner-update.create-at')}
                className="w-64"
              >
                <FullDateView date={data?.banner.createAt} />
              </FormItem>

              <FormItem
                title={t('overlay-banner-update.update-at')}
                className="w-64"
              >
                <FullDateView date={data?.banner.updateAt} />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center space-x-4 w-96">
              <SecondaryButton
                className="flex-1"
                onClick={() => {
                  navigate('../..');
                }}
              >
                {t('common.back')}
              </SecondaryButton>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32 flex-1"
                onClick={() => {
                  onSubmit().catch((e) => void e);
                }}
                loading={loading}
              >
                {t('common.update')}
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>

        <Card>
          <CardContent>
            <BannerDeleteButton bannerId={bannerId} />
          </CardContent>
        </Card>
      </div>

      <div className="sm:w-1/3 sm:mt-0 mt-4">
        <OverlayBannerPreview uploadImage={form.image} />
      </div>
    </div>
  );
}
