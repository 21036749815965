import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';
import { NailAppointmentFilter } from '@client/graphql/types/graphql.ts';

const QUERY = graphql(`
    query NailAppointments($branchId: ID!, $filter: NailAppointmentFilter!) {
        nailAppointments(branchId: $branchId, filter: $filter) {
            ...NailAppointmentItem
        }
    }
`);

export default function useNailAppointmentsQuery(
    branchId: string,
    filter: NailAppointmentFilter,
    skip = false
) {
    return useQuery(QUERY, {
        variables: {
            branchId: branchId,
            filter: filter,
        },
        skip: skip,
        pollInterval: 60 * 1000,
    });
}


