import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation CreateInternalAPIKey($input: CreateInternalAPIKeyInput!) {
    generateInternalAPIKey(input: $input)
  }
`);

export default function useCreateInternalAPIKey() {
  return useMutation(MUTATION);
}
