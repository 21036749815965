import { useSubscription } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const SUBCRIBE = graphql(`
  subscription AppointmentUpdateSubscribe($branchID: ID!) {
    appointmentUpdateSubscribe(branchID: $branchID) {
      event
      appointment {
        ...NailAppointmentItem
      }
    }
  }
`);

export default function useAppointmentUpdateSubscribe(branchID: string) {
  return useSubscription(SUBCRIBE, {
    variables: { branchID },
  });
}
