export function currencyUnit(currency: string) {
  switch (currency) {
    case 'EUR': {
      return 100;
    }
    case 'USD': {
      return 100;
    }
    case 'VND': {
      return 1;
    }
    case 'SEK': {
      return 100;
    }
    case 'CZK': {
      return 100;
    }

    default: {
      return 1;
    }
  }
}

export function currencySymbol(currency: string) {
  return Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
  }).formatToParts(1).find(x => x.type === 'currency')?.value ?? '';
}

export function formatCurrency(value: number | null, currency: string): string {
  if (value == undefined) {
    return 'N/A';
  }

  const unit: number = currencyUnit(currency);

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
  }).format(value / unit);
}

export function formatRawCurrency(value: number | null, currency: string): { value: string; symbol: string } {
  if (value == undefined) {
    return { value: '0', symbol: currencySymbol(currency) };
  }

  const unit: number = currencyUnit(currency);

  return {
    // TODO: fix this function
    // value: Intl.NumberFormat().format(value / unit),
    value: (value / unit).toString(),
    symbol: currencySymbol(currency),
  };
}

export function normalizeCurrency(value: string, currency: string) {
  if (!value) {
    return 0;
  }

  const unit: number = currencyUnit(currency);
  return Math.round(parseFloat(value) * unit);
}
