import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import classNames from 'classnames';
import FormItem from '@packages/ui/form/form-item.tsx';
import { PickupDiscountType } from '@client/graphql/types/graphql.ts';
import {
  pickupDiscountTypeUnit,
} from '@client/page/management/online-order/setting/component/pickup-discount-type-unit.tsx';
import {
  pickupDiscountTypeName,
} from '@client/page/management/online-order/setting/component/discount-type-name.tsx';
import { useTranslate } from '@tolgee/react';

const pickupDiscountTypes: readonly PickupDiscountType[] = Object.values(PickupDiscountType);

export default function PickupDiscountInput(props: {
  currency: string;
  pickupDiscountType: PickupDiscountType;
  onTypeChange: (type: PickupDiscountType) => void;
  onAmountChange: (amount: string) => void;
  pickupDiscountAmount: string;
}) {
  const { currency, pickupDiscountType, pickupDiscountAmount } = props;

  const handleDiscordType = (type: PickupDiscountType) => {
    props.onTypeChange(type);
    props.onAmountChange('0');
  };
  const { t } = useTranslate();
  const handleDiscordAmount = (type: PickupDiscountType, value: string) => {
    if (type == PickupDiscountType.Percentage && (parseInt(value) < 0 || parseInt(value) > 100)) {
      return;
    }

    props.onAmountChange(value);
  };

  return (
    <FormItem title={t('online-order.setting-page.edit.general.pickup-discount-type.title')} className="">
      <div className="flex items-center gap-1">
        <div className={'-mt-2'}>
          <MenuSelectionInput
            title={''}
            data={pickupDiscountTypes}
            className={'w-32'}
            value={pickupDiscountType}
            onChange={(newVal) => {
              handleDiscordType(newVal);
            }}
            build={(item) => {
              return {
                id: item.toString(),
                name: t('online-order.setting-page.edit.general.pickup-discount-type.menu-selection.' + pickupDiscountTypeName(item)),
              };
            }}
          />
        </div>

        <div className="relative w-24">
          <input
            type={'number'}
            value={pickupDiscountAmount}
            name={'pickupDiscountAmount'}
            placeholder={''}
            onChange={(e) => {
              handleDiscordAmount(pickupDiscountType, e.target.value);
            }}
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ',
              'placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            )}
          />

          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {pickupDiscountTypeUnit(undefined, pickupDiscountType, currency)}
          </div>
        </div>
      </div>
    </FormItem>
  );
}

