import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

export const GET_MENU_PRODUCT = graphql(`
  query GetMenuProduct($id: ID!) {
    menuProduct(id: $id) {
      id
      code
      title
      priceMode
      description
      images
      ingredients
      labels
      priceMode
      allergics
      section {
        id
        name
        description
        code
      }
      configurations {
        id
        title
        type
        values {
          id
          name
          price
        }
      }

      menuId
      sectionId
    }
  }
`);

export function useMenuProduct(id: string) {
  return useQuery(GET_MENU_PRODUCT, { variables: { id } });
}
