import { Controller, useForm } from 'react-hook-form';
import {
  CreateAppointmentInput,
  PickedProductInput,
} from '@client/page/management/appointment/create/util/appointment-create-model.ts';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import ServiceSelection from '@client/page/management/appointment/create/component/service-selection.tsx';
import moment from 'moment-timezone';
import useCreateAppointment from '@client/page/management/appointment/create/logic/use-create-appointment.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

export default function AppointmentCreate() {
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { showAlert } = useNotificationCenter();
  const navigate = useNavigate();
  const company = useFocusedCompany();
  const { t } = useTranslate();

  const [create] = useCreateAppointment();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CreateAppointmentInput>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      startTime: moment.tz(company.settings.timezone).format('YYYY-MM-DD HH:mm'),
      pickerProduct: [],
      teamId: '',
      userId: '',
    },
  });

  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const onSubmit = (data: CreateAppointmentInput) => {
    create({
      variables: {
        branchId: branchId,
        input: {
          startTime: data.startTime || moment().tz(company.settings.timezone).format('YYYY-MM-DDTHH:mm'),
          message: data.message,
          name: data.name,
          email: data.email,
          phone: data.phone,
        },
        pickedProductInput: data.pickerProduct,
        teamId: data.teamId,
        userId: data.userId,
      },
    })
      .then((e) => {
        showAlert({
          status: 'success',
          message: t('appointment.create.alert.success.message', 'Your appointment has been created successfully.'),
          title: t('appointment.create.alert.success.title', 'Success'),
        });
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        navigate(`/appointment/list/${e.data?.createAppointment.id}`);
      })
      .catch((err: Error) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);
          for (const field in validationError) {
            setError(
              field as keyof CreateAppointmentInput,
              {
                type: 'server',
                message: validationError[field],
              },
              { shouldFocus: true }
            );
          }
        }
        showAlert({
          status: 'error',
          message: err.message,
          title: 'Error',
        });
      });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 xl:grid-cols-5 gap-4 2xl:w-3/4">
      <ContentLayout className="xl:col-span-3">
        <FullPrimaryContent>
          <Card>
            <CardHeader title={t('appointment.create.header', 'Create appointment')} withBackButton={true} />

            <CardContent>
              <FormLayout>
                <FormItem title={t('appointment.create.name.title', 'Name')} className="max-w-xl">
                  <Controller
                    control={control}
                    // rules={{
                    //   required: t('appointment.update.name.validate.require', 'Name is required'),
                    //   minLength: {
                    //     value: 3,
                    //     message: t('appointment.update.name.validate.min-length', 'Min length is 3'),
                    //   },
                    //   maxLength: {
                    //     value: 50,
                    //     message: t('appointment.update.name.validate.max-length', 'Max length is 50'),
                    //   },
                    // }}
                    defaultValue={''}
                    name="name"
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label="name"
                        name="name"
                        onChange={onChange}
                        placeholder={t('appointment.update.name.placeholder', 'First name & last name')}
                        type="text"
                        error={errors.name?.message}
                        value={value}
                      />
                    )}
                  />
                </FormItem>

                <div title="" className="max-w-xl grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-8">
                  <FormItem title={t('appointment.create.email.title', 'Email')} className="max-w-xl">
                    <Controller
                      control={control}
                      defaultValue={''}
                      // rules={{
                      //   required: t('appointment.update.email.validate.require', 'Business email is required'),
                      //   pattern: {
                      //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      //     message: 'Invalid email address, example:email@gmail.com',
                      //   },
                      // }}
                      name="email"
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label="email"
                          name="email"
                          onChange={onChange}
                          placeholder={t('appointment.create.email.placeholder', 'example@gmail.com')}
                          type="text"
                          error={errors.email?.message}
                          value={value}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title={t('appointment.create.phone.title', 'Phone')} className="max-w-xl">
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="phone"
                      // rules={{
                      //   required: t('appointment.update.phone.validate.require', 'Phone is required'),
                      //   pattern: {
                      //     value: /^\d+$/,
                      //     message: t('appointment.update.phone.validate.only-number', 'Phone can only be numbers'),
                      //   },
                      // }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label="phone"
                          name="phone"
                          onChange={onChange}
                          placeholder={t('appointment.create.phone.placeholder', '09812345678')}
                          type="text"
                          error={errors.phone?.message}
                          value={value}
                        />
                      )}
                    />
                  </FormItem>
                </div>
                <Controller
                  control={control}
                  name="pickerProduct"
                  // rules={{
                  //   required: t('appointment.update.picker-product.validate.require', 'Picker product is required'),
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormItem title={t('appointment.create.service.title', 'Service')} className="max-w-xl">
                        <ServiceSelection
                          services={value}
                          setService={(pickedProduct: PickedProductInput) => {
                            onChange([pickedProduct]);
                          }}
                          setDisableSubmit={setDisabledSubmit}
                        />

                        {errors.pickerProduct && (
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {errors.pickerProduct.message}
                          </p>
                        )}
                      </FormItem>
                    </>
                  )}
                />

                <FormItem title={t('appointment.create.message.title', 'Message')} className="max-w-xl">
                  <Controller
                    control={control}
                    defaultValue={''}
                    name="message"
                    render={({ field: { onChange, value } }) => (
                      <textarea
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 xl:min-h-24
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                        name="message"
                        onChange={onChange}
                        placeholder={t('appointment.create.message.placeholder', 'Message')}
                        value={value}
                      />
                    )}
                  />
                </FormItem>

                <FormItem title={t('appointment.create.booking-time.title', 'Booking time')}>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name="startTime"
                    // rules={{
                    //   required: t('appointment.create.start-time.validate.require', 'Start time is required'),
                    // }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <input
                          className="rounded-md max-w-full border-gray-300"
                          aria-label="Start Date"
                          id="start"
                          name="start"
                          value={value}
                          type="datetime-local"
                          min={moment().format('YYYY-MM-DD HH:mm')}
                          onChange={onChange}
                        />

                        {errors.startTime && (
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {errors.startTime.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </FormItem>
              </FormLayout>
            </CardContent>

            <CardFooter>
              <PrimaryButton disabled={disabledSubmit}>
                <input
                  disabled={disabledSubmit}
                  className=""
                  type="submit"
                  value={t('common.button.create', 'Create')}
                />
              </PrimaryButton>
            </CardFooter>
          </Card>
        </FullPrimaryContent>
      </ContentLayout>
      {/*TODO hide for now */}
      {/*<ContentLayout className="xl:col-span-2">*/}
      {/*  <FullPrimaryContent>*/}
      {/*    <Controller*/}
      {/*      control={control}*/}
      {/*      defaultValue={''}*/}
      {/*      name="userId"*/}
      {/*      render={({ field: { onChange, value } }) => (*/}
      {/*        <>*/}
      {/*          <PickedTeamAndEmployee*/}
      {/*            teamId={watch('teamId')}*/}
      {/*            userId={value}*/}
      {/*            setTeamId={(teamId: string) => {*/}
      {/*              setValue('teamId', teamId);*/}
      {/*            }}*/}
      {/*            setUserId={(userId: string) => {*/}
      {/*              onChange(userId);*/}
      {/*            }}*/}
      {/*          />*/}

      {/*          {errors.userId && (*/}
      {/*            <p className="mt-2 text-sm text-red-600" id="email-error">*/}
      {/*              {errors.userId.message}*/}
      {/*            </p>*/}
      {/*          )}*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </FullPrimaryContent>*/}
      {/*</ContentLayout>*/}
    </form>
  );
}
