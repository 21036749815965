import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { useTranslate } from '@tolgee/react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import AppointmentHistoryTable from '@client/page/management/appointment/history/component/appointment-history-table.tsx';
import { useNailAppointments } from '@client/page/management/appointment/list/logic/use-appointments.ts';
import NailAppointmentListStats from '@client/page/management/appointment/list/component/nail-appointment-list-stats.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/16/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import HistorySearchBar from '@client/page/management/appointment/history/component/history-search-bar.tsx';
import { NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';

const AppointmentHistory = (props: {
  initialStartTime: Dayjs;
  initialEndTime: Dayjs;
  filter: (data: { startTime: Dayjs; endTime: Dayjs; onChange: (dates: [Dayjs, Dayjs]) => void }) => ReactNode;
  footer?: (data: { startTime: Dayjs; endTime: Dayjs; onChange: (dates: [Dayjs, Dayjs]) => void }) => ReactNode;
}) => {
  const [searchQuery] = useSearchParams();
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [startTime, setStartTime] = useState<Dayjs>(() => {
    if (searchQuery.get('nearestDetailTime')) {
      return dayjs(searchQuery.get('nearestDetailTime')).startOf('month');
    }
    return props.initialStartTime;
  });
  const [endTime, setEndTime] = useState<Dayjs>(() => {
    if (searchQuery.get('nearestDetailTime')) {
      return dayjs(searchQuery.get('nearestDetailTime')).endOf('month');
    }
    return props.initialEndTime;
  });

  const { data, loading } = useNailAppointments(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });

  const [appointments, setAppointments] = useState<NailAppointmentItemFragment[]>([]);

  useEffect(() => {
    setAppointments(data);
  }, [data, setAppointments]);

  const handleQuickPick = (type: 'currentMonth' | 'previousMonth' | 'nextMonth') => {
    let newStartTime, newEndTime;
    const today = dayjs();

    switch (type) {
      case 'currentMonth':
        newStartTime = today.startOf('month');
        newEndTime = today.endOf('month');
        break;
      case 'previousMonth':
        newStartTime = startTime.subtract(1, 'month').startOf('month');
        newEndTime = startTime.subtract(1, 'month').endOf('month');
        break;
      case 'nextMonth':
        newStartTime = startTime.add(1, 'month').startOf('month');
        newEndTime = startTime.add(1, 'month').endOf('month');
        break;
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleSearchBar = useCallback(
    (searchString: string) => {
      setAppointments(
        data.filter((appointment) => {
          if (appointment.customerVariant.name.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          if (appointment.customerVariant.phone.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          if (appointment.customerVariant.email.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        })
      );
    },
    [data, setAppointments]
  );

  return (
    <Card>
      <CardHeader title={t('appoitment-list.history.header', 'History')}>
        <div className="flex gap-4">
          <SecondaryButton
            onClick={() => {
              handleQuickPick('previousMonth');
            }}
            className="flex items-center"
          >
            <ChevronLeftIcon className="hidden sm:block w-4 h-4" />
            {t('common.button.previous-month', 'Previous Month')}
          </SecondaryButton>
          <SecondaryButton
            onClick={() => {
              handleQuickPick('currentMonth');
            }}
          >
            {t('common.button.current-month')}
          </SecondaryButton>
          <SecondaryButton
            onClick={() => {
              handleQuickPick('nextMonth');
            }}
            className="flex items-center"
          >
            {t('common.button.next-month', 'Next Month')}
            <ChevronRightIcon className="hidden sm:block w-4 h-4" />
          </SecondaryButton>
        </div>
        {props.filter({
          startTime: startTime,
          endTime: endTime,
          onChange: (dates) => {
            setStartTime(dates[0]);
            setEndTime(dates[1]);
          },
        })}
      </CardHeader>

      <CardTableContent>
        <HistorySearchBar onChange={handleSearchBar} />
        <NailAppointmentListStats items={appointments} />
        <AppointmentHistoryTable
          loading={loading}
          items={appointments}
          onClick={(id) => {
            navigate(id);
          }}
        />
      </CardTableContent>

      {props.footer && (
        <CardFooter>
          {props.footer({
            startTime: startTime,
            endTime: endTime,
            onChange: (dates) => {
              setStartTime(dates[0]);
              setEndTime(dates[1]);
            },
          })}
        </CardFooter>
      )}
    </Card>
  );
};

export default AppointmentHistory;
