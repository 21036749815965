import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/react/16/solid';

export default function ConvertPriceModeButton(props: { covertPriceMode: () => void }) {
  return (
    <div className="">
      <Menu>
        <MenuButton
          className="flex items-center gap-2 shadow-xs rounded-md h-8 px-3 font-semibold shadow-white/10
          focus:outline-none text-xl"
        >
          <EllipsisHorizontalIcon className="w-6 h-6 text-gray-700" />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom start"
          className="border border-gray-200 rounded-xl bg-white shadow-md [--anchor-gap:8px] [--anchor-padding:8px]"
        >
          <MenuItem>
            <button
              onClick={props.covertPriceMode}
              className="flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100"
            >
              <PencilIcon className="w-6 h-6" />
              Convert price matrix
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
}
