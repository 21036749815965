import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useTranslate } from '@tolgee/react';
import { useCompanyService } from '@packages/core/company/company-context.ts';


interface LocationState {
  username: string;
  password: string;
  companyId: string;
}

// TODO: Rename to quick create form result
export default function QuickCreateResult() {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { setCompanyID } = useCompanyService();

  const handleStart = () => {
    setCompanyID(state.companyId);
    navigate('/', { replace: true });
  };

  return (
    <div className="flex flex-col w-full h-screen justify-center items-center ">
      <ContentLayout className=" w-full md:w-3/4 lg:w-3/4 xl:w-2/3 2xl:w-1/3 ">
        <Helmet>
          <title>User Account</title>
          <meta name="description" content="User Account" />
        </Helmet>

        <FullPrimaryContent>
          <Card>
            <CardHeader title={t('create-form.account.header', 'Thank you for registration')}></CardHeader>
            <CardContent>
              <div>
                <FormItem title={t('create-form.account.username', 'Your Username')}>
                  {state.username}
                </FormItem>
                <FormItem title={t('create-form.account.password', 'Your Password')} className="pt-4">
                  {state.password}
                </FormItem>
              </div>
            </CardContent>
            <CardFooter>
              <PrimaryButton onClick={handleStart}
                             className="px-14">{t('create-form.account.start-button', 'Start')}</PrimaryButton>
            </CardFooter>
          </Card>
        </FullPrimaryContent>
      </ContentLayout>
    </div>
  );
}
