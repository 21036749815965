import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const GET_EPSON_DIRECT_PRINT_QUERY = graphql(`
  query GetEpsonServerDirectPrint($branchID: ID!) {
    epsonServerDirectPrints(branchID: $branchID) {
        id
        name
        
        epsonPrinterDevice {
            printerDeviceId
            
            deviceId
            abstatus
            latestPrintError
            latestPrintStatus

            printerDevice {
                name
            }
        }
    }
  }
`);

export function useEpsonServerDirectPrints(
  branchId: string | undefined,
) {
  return useQuery(GET_EPSON_DIRECT_PRINT_QUERY, {
    variables: {
      branchID: branchId ?? '',
    },
    skip: !branchId,
  });
}
