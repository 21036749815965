import { Controller, useForm } from 'react-hook-form';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Card from '@packages/ui/card/card.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import AddMember from '@client/page/management/team/team-create/component/add-member.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useTeam from '@client/page/management/appointment/service/service-product/common-component/logic/get-team.ts';
import useUpdateTeam from '@client/page/management/team/team-update/logic/use-update-team.ts';
import { getFragmentData } from '@client/graphql/types';
import { TeamFragment } from '@packages/network-graphql/common/team-fragment.ts';
import { useEffect } from 'react';

interface Team {
  name: string;
  description: string;
  members: {
    id: string;
    userId: string;
  }[];
}

// const DESCRIPTION_MAX_LENGTH = 200;

export default function TeamUpdate() {
  const teamId = useParams().teamId ?? '';
  const navigate = useNavigate();
  const { showAlert } = useNotificationCenter();

  const { data } = useTeam(teamId);
  const [update] = useUpdateTeam();

  const { control, handleSubmit, watch, getValues, setValue, reset } = useForm<Team>({
    defaultValues: {
      name: '',
      description: '',
      members: [],
    },
  });

  const teamData = getFragmentData(TeamFragment, data?.team);

  useEffect(() => {
    reset({
      name: teamData?.name ?? '',
      description: teamData?.description ?? '',
      members:
        teamData?.teamMember.map((member) => ({
          id: member.id,
          userId: member.userId,
        })) ?? [],
    });
  }, [teamData, reset]);

  const handleMember = (isChecked: boolean, userId: string) => {
    const currentMembers = getValues('members');
    if (isChecked) {
      setValue('members', [...currentMembers, { id: 'random', userId: userId }]);
      return;
    }
    setValue(
      'members',
      currentMembers.filter((member) => member.userId !== userId)
    );
  };

  const onSubmit = (value: Team) => {
    update({
      variables: {
        id: teamId,
        input: {
          name: value.name,
          description: value.description,
        },
        memberTeam: value.members,
      },
    })
      .then(() => {
        showAlert({
          title: 'Success',
          status: 'success',
          message: 'Create team success!',
        });
        navigate('..');
      })
      .catch((e: Error) => {
        showAlert({
          title: 'Success',
          status: 'success',
          message: e.message,
        });
      });
  };

  const currentMember = watch('members').map((member) => ({ userId: member.userId }));

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
      <ContentLayout className="xl:col-span-2">
        <FullPrimaryContent>
          <Card>
            <CardHeader title={'Add team'} withBackButton={true} />

            <CardContent>
              <FormLayout>
                <FormItem title="Name" className="max-w-md">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        label="name"
                        value={value}
                        name="name"
                        placeholder="Name"
                        onChange={onChange}
                      />
                    )}
                  />
                </FormItem>

                {/*<FormItem*/}
                {/*  subtitle={`(${String(watch('description').length)}/${String(DESCRIPTION_MAX_LENGTH)})`}*/}
                {/*  className="max-w-lg"*/}
                {/*  title={'Description'}*/}
                {/*>*/}
                {/*  <Controller*/}
                {/*    control={control}*/}
                {/*    name="description"*/}
                {/*    render={({ field: { onChange, value } }) => (*/}
                {/*      <textarea*/}
                {/*        className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36"*/}
                {/*        placeholder={'Description'}*/}
                {/*        value={value}*/}
                {/*        name=""*/}
                {/*        onChange={onChange}*/}
                {/*        maxLength={DESCRIPTION_MAX_LENGTH}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*</FormItem>*/}
              </FormLayout>
            </CardContent>

            <CardFooter>
              <PrimaryButton
                onClick={() => {
                  void handleSubmit(onSubmit)();
                }}
              >
                Update
              </PrimaryButton>
            </CardFooter>
          </Card>
        </FullPrimaryContent>
      </ContentLayout>

      <ContentLayout>
        <FullPrimaryContent>
          <AddMember currentMember={currentMember} handleMember={handleMember} />
        </FullPrimaryContent>
      </ContentLayout>
    </div>
  );
}
