import {
  useEpsonServerDirectPrints,
} from '@client/page/management/printer/epson-server-direct-print-create/logic/use-epson-service-direct-print-create.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from 'ui/src/button/primary-button';
import useFormData from '@client/module/utils/use-form-data.ts';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

interface Form {
  name: string;
}

const EpsonServerDirectPrintCreatePage = (props: { onClose: () => void }) => {
  const { branch } = useFocusedBranchContext();
  const [create, { loading }] = useEpsonServerDirectPrints();

  const { showAlert } = useNotificationCenter();

  const { data, handle } = useFormData<Form>({
    name: '',
  });

  const onSubmit = () => {
    create({
      variables: {
        input: {
          branchId: branch?.id ?? '',
          name: data.name,
        },
      },
    }).then(() => {
      props.onClose();

      showAlert({
        status: 'success',
        title: 'Success',
        message: 'Successfully created server',
      });
    }).catch(() => {
      showAlert({
        status: 'error',
        title: 'Error',
        message: 'Failed to create server',
      });
    });
  };

  return (
    <Card>
      <CardHeader title={'Epson Service Direct Print Create'} />

      <CardContent>
        <FormLayout>

          <TextInput
            type="text"
            label="name"
            value={data.name}
            name="name"
            placeholder="Name"
            onChange={handle}
          />

        </FormLayout>
      </CardContent>

      <CardFooter>
        <PrimaryButton onClick={onSubmit} loading={loading}>Create</PrimaryButton>
        <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
      </CardFooter>

    </Card>
  );
};

export default EpsonServerDirectPrintCreatePage;
