import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation updateHoliday($id: ID!, $input: UpdateHolidayInput!) {
    updateHoliday(id: $id, input: $input) {
      id
      startTime
      endTime
      description
    }
  }
`);

export default function useUpdateHoliday() {
  return useMutation(MUTATION, {
    refetchQueries: ['holidays'],
  });
}
