import { NailAppointmentConfirmationStatus } from '@client/graphql/types/graphql.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import useUpdateAppointmentConfirmationStatus from '@client/page/management/appointment/detail/logic/use-update-appointment-confirmation-status.ts';

export function AppointmentConfirmationStatusSelection(props: {
  appointmentId: string;
  status: NailAppointmentConfirmationStatus;
  refetchData: () => void;
}) {
  const notificationCenter = useNotificationCenter();
  const [update] = useUpdateAppointmentConfirmationStatus();
  const { t } = useTranslate();
  const onUpdateStatusHandler = (status: NailAppointmentConfirmationStatus) => {
    update({
      variables: {
        id: props.appointmentId,
        status: status,
      },
    })
      .then(() => {
        notificationCenter.showAlert({
          status: 'success',
          message: 'Update success!',
          title: 'Update appointment',
        });
        props.refetchData();
      })
      .catch((error: Error) => {
        notificationCenter.showAlert({
          status: 'error',
          message: 'Update failed! ' + error.message,
          title: 'Update appointment',
        });
      });
  };

  return (
    <>
      <p className="-mb-6 text-sm">{t('appointment.appointment-detail.status.title', 'Confirmation status')}</p>
      <Menu>
        <MenuButton
          className={
            'flex justify-center items-center gap-1 focus:outline-none rounded-md bg-white font-semibold' +
            ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2 py-1 text-sm'
          }
        >
          <AppointmentStatus status={props.status} />
        </MenuButton>
        <MenuItems
          anchor="bottom start"
          className={
            'focus:outline-none absolute min-w-28 mt-1 z-50 rounded-md border border-gray-300  p-1 text-sm/6 bg-white'
          }
        >
          <MenuItem>
            <span
              onClick={() => {
                onUpdateStatusHandler(NailAppointmentConfirmationStatus.Confirmed);
              }}
              className="flex px-2 py-2 gap-1 hover:bg-gray-200"
            >
              <AppointmentStatus status={NailAppointmentConfirmationStatus.Confirmed} />
            </span>
          </MenuItem>
          <MenuItem>
            <span
              onClick={() => {
                onUpdateStatusHandler(NailAppointmentConfirmationStatus.Cancelled);
              }}
              className="flex px-2 py-2 gap-1 hover:bg-gray-200"
            >
              <AppointmentStatus status={NailAppointmentConfirmationStatus.Cancelled} />
            </span>
          </MenuItem>
          <MenuItem>
            <span
              onClick={() => {
                onUpdateStatusHandler(NailAppointmentConfirmationStatus.Waiting);
              }}
              className="flex px-2 py-2 gap-1 hover:bg-gray-200"
            >
              <AppointmentStatus status={NailAppointmentConfirmationStatus.Waiting} />
            </span>
          </MenuItem>
        </MenuItems>
      </Menu>
    </>
  );
}

export function AppointmentStatus(props: { status: NailAppointmentConfirmationStatus }) {
  const { status } = props;
  const { t } = useTranslate();

  switch (status) {
    case NailAppointmentConfirmationStatus.Confirmed:
      return (
        <span className="flex gap-1 items-center">
          <CheckCircleIcon className="w-6 h-6 fill-green-500" />
          <span>{t('common.confirmation.confirm', 'Confirm')}</span>
        </span>
      );
    case NailAppointmentConfirmationStatus.Cancelled:
      return (
        <span className="flex gap-1 items-center">
          <XCircleIcon className="w-6 h-6 fill-red-500" />
          <span>{t('common.confirmation.cancel', 'Cancel')}</span>
        </span>
      );
    case NailAppointmentConfirmationStatus.Waiting:
      return (
        <span className="flex gap-1 items-center">
          <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />
          <span>{t('common.confirmation.waiting', 'Waiting')}</span>
        </span>
      );
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}
