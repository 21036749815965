import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';
import { OpeningTimeServiceType } from '@client/graphql/types/graphql';

const COPY_OPENING_TIME_MUTATION = graphql(`
  mutation CopyOpeningTime($branchID: ID!, $sourceService: OpeningTimeServiceType!, $targetService: OpeningTimeServiceType!) {
    copyOpeningTimes(branchID: $branchID, sourceService: $sourceService,targetService: $targetService)
  }
`);

export function useCopyOpeningTime(branchID: string, sourceService: OpeningTimeServiceType, targetService: OpeningTimeServiceType) {
  return useMutation(COPY_OPENING_TIME_MUTATION,{
    variables: {
      branchID,
      sourceService,
      targetService
    },
    refetchQueries: ['OpeningTimes']
  });
}
