import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useCustomerHistory from '@client/page/management/appointment/detail/logic/use-customer-history.ts';
import moment from 'moment';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { useTranslate } from '@tolgee/react';

export default function CustomerHistory(props: {
  appointmentId: string;
  customerId: string;
  currentAppointmentTime: string;
}) {
  const { data } = useCustomerHistory(props.customerId);
  const company = useFocusedCompany();
  const { t } = useTranslate();

  if (!data) {
    return null;
  }

  const customerData = data.customerAppointmentHistory.filter((item) => item.appointmentId !== props.appointmentId);

  return (
    <>
      {customerData.length !== 0 && (
        <Card>
          <CardHeader title={t('appointment.appointment-detail.customer-history.title', 'Customer History')} />

          <CardContent>
            <p className="mb-2">
              {t('appointment.appointment-detail.customer-history.booking-history.title', 'Booking history')}
            </p>
            <ul className="space-y-1">
              {customerData.map((item) => (
                <li className="space-x-1.5" key={item.appointmentId}>
                  <span className="text-gray-500">
                    {moment(item.time).tz(company.settings.timezone).format('DD MMM')}
                  </span>
                  <span>-</span>
                  <span>{item.detail}</span>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </>
  );
}
