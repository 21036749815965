import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
export default function OverlapDialog(props: {
  onCLose: () => void;
  data: { reservation: number; appointment: number; onlineOrder: number };
  action: () => void;
}) {
  const { t } = useTranslate();

  return (
    <Card>
      <CardHeader title={t('holiday.dialog.overlap.header', 'Alert')} />
      <CardContent>
        <div className="space-y-4">
          <p className="text-red-600">
            {t(
              'holiday.dialog.overlap.message',
              'You’re setting a holiday on a date with pending services. Please review these before proceeding to avoid impacting any customers.'
            )}
          </p>
          <div className="space-y-4 text-gray-600 border border-gray-400 px-4 py-4 rounded-md">
            {props.data.reservation > 0 && (
              <p>
                <span className="text-base">
                  {t('holiday.dialog.overlap.table-reservation', 'Reservation overlap')}:{' '}
                </span>
                <span>{props.data.reservation}</span>
              </p>
            )}
            {props.data.onlineOrder > 0 && (
              <p>
                <span className="text-base">{t('holiday.dialog.overlap.online-order', 'Online order overlap')}: </span>
                <span>{props.data.onlineOrder}</span>
              </p>
            )}
            {props.data.appointment > 0 && (
              <p>
                <span className="text-base">{t('holiday.dialog.overlap.appointment', 'Appointment overlap')}: </span>
                <span>{props.data.appointment}</span>
              </p>
            )}
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <PrimaryButton
          onClick={() => {
            props.action();
          }}
        >
          {t('holiday.dialog.overlap.continue-button', 'Still continue')}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => {
            props.onCLose();
          }}
        >
          {t('common.button.cancel', 'Cancel')}
        </SecondaryButton>
      </CardFooter>
    </Card>
  );
}
