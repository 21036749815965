import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CREATE_EPSON_DIRECT_PRINT_QUERY = graphql(`
    mutation CreateEpsonServerDirectPrint($input: CreateEpsonServerDirectPrintInput!) {
        createEpsonServerDirectPrint(input: $input) {
            id
        }
    }
`);

export function useEpsonServerDirectPrints() {
  return useMutation(CREATE_EPSON_DIRECT_PRINT_QUERY, {
      refetchQueries: ['GetEpsonServerDirectPrint'],
  });
}
