import Card from '@packages/ui/card/card.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useCreateMenu } from '@client/page/management/menu/menu-create/logic/use-create-menu.ts';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error.ts';
import { useNavigate } from 'react-router-dom';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useTranslate } from '@tolgee/react';
import { MenuProductPriceMode } from '@client/graphql/types/graphql.ts';
import { useForm, Controller } from 'react-hook-form';
import { captureException } from '@sentry/react';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { ApolloError } from '@apollo/client';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { MenuSource } from '@packages/core/graphql/types/graphql.ts';


interface FormValues {
  title: string;
  allergics: string;
  preferredProductPriceMode: MenuProductPriceMode;
  menuSource: MenuSource;
}

export default function MenuCreate() {
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const priceModeOption = Object.values(MenuProductPriceMode);
  const { showAlert } = useNotificationCenter();

  const [create, { loading }] = useCreateMenu();

  const form = useForm<FormValues>({
    defaultValues: {
      title: '',
      allergics: '',
      preferredProductPriceMode: MenuProductPriceMode.Simple,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, handleSubmit, control, setError } = form;

  const onSubmit = async (data: FormValues) => {
    try {
      const result = await create({
        variables: {
          companyId: companyId,
          input: {
            title: data.title.trim(),
            allergics: data.allergics,
            preferredProductPriceMode: data.preferredProductPriceMode,
            menuSource: MenuSource.Restaurant,
          },
        },
      });
      if (result.data) {
        showAlert({
          status: 'success',
          title: t('menu.menu-create.notification.success.title', 'Menu created'),
          message: t('menu.menu-create.notification.success.message', 'Menu has been created successfully'),
        });
        navigate('..');
      } else {
        showAlert({
          status: 'error',
          title: t('menu.menu-create.notification.error.title', 'Menu creation failed'),
          message: t('menu.menu-create.notification.error.message', 'Failed to create menu'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('menu.menu-create.header')} />

          <CardContent>
            <form className="space-y-6"
                  onSubmit={() => {
                    handleSubmit(onSubmit);
                  }}>
              <FormItem className="max-w-md" title={t('menu.menu-create.form-input.menu-title.title')}>
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                  type="text"
                  {...register('title',
                    {
                      required: t('menu.menu-create.form-input.menu-title.validation.required', 'Menu title is required')
                      ,
                      minLength: {
                        value: 3,
                        message: t('menu.menu-create.form-input.menu-title.validation.min-length', 'Menu title must be at least 3 characters'),
                      },
                      maxLength: {
                        value: 50,
                        message: t('menu.menu-create.form-input.menu-title.validation.max-length', 'Menu title must be at most 50 characters'),
                      }
                      ,
                      pattern: {
                        value: /^\S.*\S$/,
                        message: t('menu.menu-create.form-input.menu-title.validation.no-space', 'Menu title must not start or end with whitespace'),
                      },
                    },
                  )}
                />
                {form.formState.errors.title && (
                  <p className="text-red-500 text-xs pt-1">{form.formState.errors.title.message}</p>
                )}
              </FormItem>

              <FormItem className="max-w-md" title={t('menu.menu-create.form-input.menu-allergics.title', 'Allergy')}>
                <textarea
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                  {...register('allergics')}
                />
                {form.formState.errors.allergics && (
                  <p className="text-red-500 text-xs pt-1">{form.formState.errors.allergics.message}</p>
                )}
              </FormItem>

              <Controller
                control={control}
                name="preferredProductPriceMode"
                render={({ field }) => (
                  <MenuSelectionInput
                    title={t('menu.menu-create.form-input.menu-price-mode.title', 'Prefer Price mode')}
                    data={priceModeOption}
                    value={field.value}
                    onChange={field.onChange}
                    build={(item) => ({
                      id: item,
                      name: t(`menu.menu-create.form-input.menu-price-mode.${item.toLowerCase()}`, item.toLowerCase()),
                    })}
                  />
                )}
              />
            </form>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center">
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32"
                onClick={() => {
                  handleSubmit(onSubmit)().catch(captureException);
                }}
                loading={loading}

              >
                {t('common.button.create')}
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
