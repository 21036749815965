import blackLogo from '../../../public/black-logo.png';
import ninetysix from '../../../public/ninetysix-black.png';

export default function FooterBar() {
  return (
    <footer className="">
      <div
        className="w-full md:w-3/4 lg:w-3/4 xl:w-2/3 2xl:w-1/3 mx-auto max-w-7xl overflow-hidden pb-6 pt-20 sm:pt-24 lg:px-8">
        <div
          aria-label="Footer"
          className="flex flex-wrap justify-center items-center space-x-2 -mb-6 sm:flex-nowrap sm:mr-10">
          <img
            className="h-10"
            src={blackLogo}
            alt="Digital InHouse 2"
          />
          <img
            className="h-24 w-auto mb-1"
            src={ninetysix}
            alt="Digital InHouse 2"
          />
        </div>

        <p className="mt-4 text-center text-xs leading-5 text-gray-500">
          inHouse is a software product copyrighted by Ninety Six Digital Ltd.,
          Tax code 0110507314, Registration Company in Hanoi, Vietnam.
          Consulting branches in Mannheim, Frankfurt a.M and Munich, Germany.

        </p>
        <p className=" text-center text-xs leading-5 text-gray-500"> Details at: <a className='underline' href="https://www.ninetysixdigital.com/">www.ninetysixdigital.com</a></p>

      </div>
    </footer>
  );
}