const MENU_PORT_URL = import.meta.env.VITE_MENU_PORT_URL as string;

class MenuPort {
  async importMenu(menuId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await fetch(`${MENU_PORT_URL}/file/menu/${menuId}/upload`, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    });
  }
}

export const menuPort = new MenuPort();
