import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  UserNotificationData,
} from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-data.ts';

export default function UserNotificationTable(props: { loading?: boolean; items: UserNotificationData[] | undefined }) {
  const { items } = props;

  if (!items) {
    return <div>Empty data</div>;
  }

  return (
    <>
      <Table
        loading={props.loading}
        data={items}
        build={(item) => <TableGroupRow key={item.id} userNotification={item} />}
      >
        <TableHeaderColumn>Title</TableHeaderColumn>
        <TableHeaderColumn>Message</TableHeaderColumn>
        <TableHeaderColumn>Created at</TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { userNotification: UserNotificationData }) {
  const { userNotification } = props;

  const link = userNotification.link;
  let onClickHandler: (() => void) | undefined;
  if (link) {
    onClickHandler = () => {
      window.location.replace(link);
    };
  } else {
    onClickHandler = undefined;
  }

  return (
    <>
      <TableRow key={userNotification.id} className="hover:bg-gray-100" onClick={onClickHandler}>
        <TableRowColumn className="py-6 max-sm:py-8 max-sm:leading-5 tracking-wider	">
          <p className="w-full font-semibold">{userNotification.title}</p>
        </TableRowColumn>
        <TableRowColumn className="max-sm:leading-5 tracking-wider">
          <p className="w-full">{userNotification.message}</p>
        </TableRowColumn>
        <TableRowColumn className="max-sm:leading-5 tracking-wider">
          <p className="w-full text-gray-500">
            {lodash.capitalize(moment(parseInt(userNotification.createdAt)).fromNow())}
          </p>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={userNotification.link ?? ''}>
            <ChevronRightIcon className="w-7 text-gray-400" />
          </Link>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
