import { OpeningTimeServiceType } from '@client/graphql/types/graphql.ts';
import OpeningTimeSetup from '@client/page/management/opening-time/opening-time-setup/opening-time-setup.tsx';

export default function AppointmentBranchConfigurationTime() {
  return (
    <>
      <OpeningTimeSetup title={'Appointment time'} service={OpeningTimeServiceType.Appointment} />
    </>
  );
}
