import { RouteObject } from 'react-router-dom';
import TeamList from '@client/page/management/team/team-list/team-list.tsx';
import TeamCreate from '@client/page/management/team/team-create/team-create.tsx';
import TeamUpdate from '@client/page/management/team/team-update/team-update.tsx';

export const TeamRouter: RouteObject = {
  path: 'team',
  children: [
    {
      path: '',
      element: <TeamList />,
    },
    {
      path: 'create',
      element: <TeamCreate />,
    },
    {
      path: ':teamId',
      children: [
        {
          path: '',
          element: <TeamUpdate />,
        },
      ],
    },
  ],
};
