import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const UPDATE_HUBPAGE_QUERY = graphql(`
  mutation updateHubPage($companyID: ID!, $input: HubPageUpdateInput!) {
    updateHubPage(companyID: $companyID, input: $input) {
      companyId
    }
  }
`);

export function useUpdateHubpage() {
  return useMutation(UPDATE_HUBPAGE_QUERY, {});
}
