import { NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment } from 'react';
import { useTranslate } from '@tolgee/react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import Table from '@packages/ui/table/table.tsx';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { AppointmentTableListConfirmationStatus } from '@client/page/management/appointment/list/component/appointment-table-list-confirmation-status.tsx';

export default function AppointmentHistoryTable(props: {
  loading?: boolean;
  items: NailAppointmentItemFragment[];
  onClick?: (id: string) => void;
}) {
  const { items, loading } = props;
  const { t } = useTranslate();
  const company = useFocusedCompanyContext();

  if (items.length === 0 && loading == false) {
    return <div className="px-6 py-4">{t('appointment.no-appointment')}</div>;
  }

  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(company.settings.timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, appointments]) => {
    const sortedReservations = Array.from(appointments);
    sortedReservations.sort((a, b) => {
      return moment(a.startTime).isBefore(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      appointments: sortedReservations,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    return moment(a.date).isBefore(moment(b.date)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-blue-500">
                  {item.date == moment().tz(company.settings.timezone).format('DD MMM')
                    ? t('appointment.history.list.section.today', 'Today')
                    : item.date}
                </span>
              </div>
            </TableSection>

            {item.appointments.map((appointment) => (
              <AppointmentRow
                key={appointment.id}
                item={appointment}
                timezone={company.settings.timezone}
                onClick={() => props.onClick?.(appointment.id)}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        {/*<TableHeaderColumn className="max-sm:hidden">*/}
        {/*  Platform*/}
        {/*</TableHeaderColumn>*/}
        <TableHeaderColumn className="w-14">{t('common.table-header-column.status', 'Status')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.time', 'Time')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.guest', 'Guest')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('common.table-header-column.service', 'Service')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden xl:table-cell">
          {t('common.table-header-column.message', 'Message')}
        </TableHeaderColumn>
      </Table>
    </>
  );
}

function AppointmentRow(props: { item: NailAppointmentItemFragment; onClick: () => void; timezone: string }) {
  const { item, timezone, onClick } = props;
  const serviceName = item.products[0] ? item.products[0].pickedProduct.title : '';

  return (
    <>
      <TableRow key={item.id} className="cursor-pointer" onClick={onClick}>
        {/* Platform */}
        {/*<TableRowColumn className="max-sm:hidden">*/}
        {/*  {item.platformType}*/}
        {/*</TableRowColumn>*/}

        <TableRowColumn>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <AppointmentTableListConfirmationStatus appointmentId={item.id} status={item.confirmationStatus} />
          </div>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="font-bold">{appointmetnTimeFormat(item.startTime ?? '', timezone)}</TableRowColumn>

        {/* Name */}
        <TableRowColumn className="max-w-[80px] sm:max-w-32">
          <span>{item.customerVariant.name}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        {/* Service */}
        <TableRowColumn className="hidden sm:table-cell max-w-12 xl:max-w-32 truncate">
          <span className="font-semibold truncate  ">{serviceName}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        <TableRowColumn className="hidden xl:table-cell max-w-0.5 truncate overflow-hidden break-all">
          {item.message}
        </TableRowColumn>
      </TableRow>
    </>
  );
}

function appointmetnTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
