import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation deleteHoliday($id: ID!) {
    deleteHoliday(id: $id) {
      id
      startTime
      endTime
      description
    }
  }
`);

export default function useDeleteHoliday() {
  return useMutation(MUTATION, {
    refetchQueries: ['holidays'],
  });
}
